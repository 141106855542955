import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

export const header = [
  {
    label: 'S.N',
    value: 'S.N',
  },
  {
    label: 'Category',
    value: 'categoryName',
  },
  {
    label: 'Item',
    value: 'itemName',
  },
  {
    label: 'Jan',
    value: 'year-01',
  },
  {
    label: 'Feb',
    value: 'year-02',
  },
  {
    label: 'March',
    value: 'year-03',
  },
  {
    label: 'April',
    value: 'year-04',
  },
  {
    label: 'May',
    value: 'year-05',
  },
  {
    label: 'June',
    value: 'year-06',
  },
  {
    label: 'July',
    value: 'year-07',
  },
  {
    label: 'Aug',
    value: 'year-08',
  },
  {
    label: 'Sept',
    value: 'year-09',
  },
  {
    label: 'Oct',
    value: 'year-10',
  },
  {
    label: 'Nov',
    value: 'year-11',
  },
  {
    label: 'Dec',
    value: 'year-12',
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
