import React from 'react'
import {GoogleMap, LoadScript, MarkerF, Marker, useLoadScript} from '@react-google-maps/api'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
// Coordinates of Acropolis in Athens, Greece
const center = {
  lat: 37.970833,
  lng: 23.72611,
}

function MapComponent({zooms = 7, data = []}: any) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: 'AIzaSyD32soTrhrAXzmKQp7rH8NsL8NQMhmdqlY',
    // libraries: ['marker'],
  })

  const newData = data.map((x, ind) => {
    return {
      id: ind,
      name: x.name,
      ...x.position,
    }
  })
  const {pathname} = useLocation()
  console.log({data})

  const containerStyle = {
    width: pathname.includes('collect-orders') ? '800px' : '100%',
    height: pathname.includes('collect-orders') ? '600px' : '700px',
  }

  const getMarkerIcon = () => {
    if (typeof google !== 'undefined' && google.maps) {
      return {
        url: toAbsoluteUrl('/media/location/marker.png'),
        scaledSize: new google.maps.Size(50, 50), // Ensure google.maps.Size is used here
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      }
    }
    return {} // Return a default empty icon if google is not loaded
  }

  const markerIcon = {
    url: toAbsoluteUrl('/media/location/marker.png'),
    // scaledSize: new google.maps.Size(50, 50), // Use google.maps.Size here
    // origin: new google.maps.Point(0, 0),
    // anchor: new google.maps.Point(0, 0),
  }
  return (
    <div className='App'>
      <LoadScript googleMapsApiKey={'AIzaSyD32soTrhrAXzmKQp7rH8NsL8NQMhmdqlY'}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          mapContainerClassName='map-container'
          center={newData?.[0] || {}}
          zoom={zooms}
        >
          {newData.map((location) => (
            <Marker
              key={location.id}
              position={{lat: location.lat, lng: location.lng}}
              title={location.name}
              // icon={markerIcon}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default MapComponent
