import React from 'react'
import {Chart} from 'react-google-charts'
import {generateAddress, toAbsoluteUrl} from '../../../../../../_metronic/helpers'

interface MonthlyReportProps {
  companyName?: string
  companyAddress?: string
  month?: string
  inventory?: any
  analyticsMonth?: any
  graphData?: any
}

export const data = [
  ['From', 'To', 'Weight'],
  ['A', 'X', 5],
  ['A', 'Y', 7],
  ['A', 'Z', 6],
  ['B', 'X', 2],
  ['B', 'Y', 9],
  ['B', 'Z', 4],
]
const options = {}

const inventoryHeader = [
  {label: 'Category', name: 'categoryName'},
  {label: 'Item', name: 'itemName'},
  {label: 'Open Inv', name: 'openInventory'},
  {label: 'Collected', name: 'received'},
  {label: 'Processed', name: 'processed'},
  {label: 'Supplied', name: 'supplied'},
  {label: 'Closing Inv', name: 'closingInventory'},
]

const MonthlyReport: React.FC<MonthlyReportProps> = ({
  companyName = 'Company Name',
  companyAddress = 'Company Address',
  month = 'October 2024',
  inventory = {},
  analyticsMonth = {},
  graphData = {},
}) => {
  let pieChartData1 = [['Process', 'Amount']]
  let pieChartData2 = [['Process', 'Amount']]
  analyticsMonth?.processDetails?.map((x) => {
    return pieChartData1.push([x.name, x.quantity])
  })
  analyticsMonth?.suppliedDetails?.map((x) => {
    return pieChartData2.push([x.pickupPointName, x.quantitySupplied])
  })

  // Generate days array for x-axis
  const days = Array.from({length: 30}, (_, i) => `Day ${i + 1}`)
  // Data for trend charts
  const collectedData =
    (graphData?.collectionDetails && Object.values(graphData.collectionDetails)) || []
  const wastageData = (graphData?.wastageDetails && Object.values(graphData.wastageDetails)) || []
  const processingData =
    (graphData?.productionDetails && Object.values(graphData.productionDetails)) || []
  const supplyData = (graphData?.returnDetails && Object.values(graphData.returnDetails)) || []
  const receivedData =
    (graphData?.receivedDetails && Object.values(graphData.receivedDetails)) || []

  // Prepare data for trend charts
  const trendData: any = [['Day', 'Collected' /*  'Received' */]]
  const processingChartData: any = [['Day', 'Processing']]
  const supplyChartData: any = [['Day', 'Supply']]
  const wastageChartDataData: any = [['Day', 'Supply']]
  const receivedChartDataData: any = [['Day', 'Supply']]

  days.forEach((day, index) => {
    trendData.push([day, collectedData[index] /* receivedData[index] */])
    processingChartData.push([day, processingData[index]])
    supplyChartData.push([day, supplyData[index]])
    wastageChartDataData.push([day, wastageData[index]])
    receivedChartDataData.push([day, receivedData[index]])
  })
  // console.log({graphData, analyticsMonth, supplyChartData})

  return (
    <div
      className='max-w-4xl mx-auto p-5 font-sans'
      style={{
        width: '870px',
        // border: '2px solid lightgray',
        margin: '0 auto',
        padding: '10px',
        fontFamily: 'Arial',
      }}
    >
      <div className='border-b-2 border-gray-300 pb-2'>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td style={{width: '90%', textAlign: 'left'}}>
                <h1
                  className='text-2xl text-blue-900 italic m-0'
                  // style={{ borderBottom: '2px solid gray' }}
                >
                  <em style={{color: 'rgb(28, 67, 121)'}}> Monthly Report</em>
                </h1>
              </td>
              <td style={{width: '30%', textAlign: 'right'}}>
                <img
                  src={toAbsoluteUrl('/media/imgs/in.png')}
                  alt='Company Logo'
                  style={{maxHeight: '50px'}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-4 w-full'>
        <table
          className='w-full border-collapse'
          style={{
            width: '750px',
          }}
        >
          <tbody>
            <tr>
              <td className='py-2 w-3/4'>
                <div>
                  <strong>Company Name:</strong> {analyticsMonth?.pickupPointName || companyName}
                </div>
                <div>
                  <strong>Address:</strong>{' '}
                  {generateAddress(analyticsMonth?.pickupPointAddress) || companyAddress}
                </div>
                <div>
                  <strong>Month:</strong> {month}
                </div>
              </td>
              {/*  <td className='py-2 text-right'>
                <img
                  src={toAbsoluteUrl('/media/imgs/companyLogo.jpg')}
                  alt='Company Logo'
                  className='h-14 w-auto'
                />
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-4'>
        <h4 className='text-lg font-bold'>Executive Summary</h4>
        <p style={{margin: '0', padding: '0'}}>
          This section summarizes the key findings and insights from the monthly report.
        </p>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '10px',
          padding: '4px',
        }}
      >
        {[
          {label: 'Collected', value: '2,500 kg', name: 'collected'},
          {label: 'Processed', value: '2,400 kg', name: 'processed'},
          {label: 'Diverted', value: '1,800 kg', name: 'diversionProductionQuantity'},
          {label: 'Supplied', value: '2,300 kg', name: 'supplied'},
          {label: 'Received', value: '1,500 kg', name: 'received'},
          {label: 'Closing Stock', value: '1,000', name: 'stock'},
        ].map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#f3f4f6',
              border: '1px solid #d1d5db',
              borderRadius: '6px', // Slightly smaller border radius
              padding: '10px', // Reduced padding
              textAlign: 'left',
              boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)', // Reduced shadow
              fontSize: '0.9rem', // Smaller font size
              margin: '2px',
            }}
          >
            <p style={{fontWeight: 'bold', margin: '0'}}>{item.label}</p>
            <p style={{margin: '0'}}>
              {analyticsMonth[item.name]} {item.label !== 'Plastic Credits' && ' kg'}
            </p>
          </div>
        ))}
      </div>

      <table style={{width: '95%', borderCollapse: 'collapse'}}>
        <tbody>
          <tr>
            <td style={{width: '50%', verticalAlign: 'top', padding: '10px'}}>
              <h4>Production by Process</h4>
              <Chart
                width='100%'
                height='300px'
                chartType='PieChart'
                data={pieChartData1}
                options={{
                  legend: {position: 'left'},
                  pieSliceText: 'percentage',
                }}
              />
            </td>
            <td style={{width: '50%', verticalAlign: 'top', padding: '10px'}}>
              <h4>Customer wise dispatch</h4>
              {/* <Chart chartType='Sankey' width='100%' height='250px' data={data} options={options} /> */}
              <Chart
                width='100%'
                height='300px'
                chartType='PieChart'
                data={pieChartData2}
                options={{
                  legend: {position: 'left'},
                  pieSliceText: 'percentage',
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <h4 className='text-lg font-bold my-6'>Inventory Table</h4>
      <table
        style={{width: '90%', borderCollapse: 'collapse', margin: ' 0 auto', fontSize: '13px'}}
      >
        <thead>
          <tr>
            {inventoryHeader.map((x, ind) => (
              <th
                style={{
                  backgroundColor: '#4472C4',
                  color: 'white',
                  padding: '8px',
                  border: '1px solid gray',
                  textAlign: 'center',
                }}
                key={ind + 1 + ''}
              >
                {x.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {inventory.map((y, ind) => (
            <tr key={ind + 1 + ''}>
              {inventoryHeader.map((xy: any, int) => (
                <td
                  key={int + 1 + ''}
                  style={{
                    backgroundColor: '#f3f3f3',
                    padding: '8px',
                    border: '1px solid gray',
                    textAlign: 'center',
                  }}
                >
                  {y?.[xy.name] || ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className=' my-4'>
        <h4 className='text-lg font-bold mb-4'>Daily Trends</h4>
        <Chart
          width='700px'
          height='500px'
          chartType='ComboChart'
          data={trendData}
          options={{
            title: 'Day wise material incoming (Kg)',
            seriesType: 'bars',
            series: {
              0: {color: '#36A2EB'},
              1: {color: '#FF6384'},
            },
            hAxis: {title: 'Day'},
            vAxis: {title: 'Amount (kg)'},
            bar: {groupWidth: '70%'},
            legend: 'none',
          }}
        />

        <div className='print-section no-break my-4'>
          <Chart
            width='700px'
            height='500px'
            chartType='ColumnChart'
            data={processingChartData}
            options={{
              title: 'Day wise production (Kg)',
              series: {
                0: {color: '#4BC0C0'},
              },
              hAxis: {title: 'Day'},
              vAxis: {title: 'Amount (kg)'},
              bar: {groupWidth: '70%'},
              legend: 'none',
            }}
          />
        </div>

        <div className='print-section no-break my-4'>
          <Chart
            width='700px'
            height='500px'
            chartType='ColumnChart'
            // data={receivedChartDataData}
            data={supplyChartData}
            options={{
              title: 'Day wise material received from other facilities (Kg)',
              series: {
                0: {color: '#9966FF'},
              },
              hAxis: {title: 'Day'},
              vAxis: {title: 'Amount (kg)'},
              bar: {groupWidth: '70%'},
              legend: 'none',
            }}
          />
        </div>

        <div className='print-section no-break my-4'>
          <Chart
            width='700px'
            height='500px'
            chartType='ColumnChart'
            data={wastageChartDataData}
            options={{
              title: 'Day wise material wastage (Kg)',
              series: {
                0: {color: '#9966FF'},
              },
              hAxis: {title: 'Day'},
              vAxis: {title: 'Amount (kg)'},
              bar: {groupWidth: '70%'},
              legend: 'none',
            }}
          />
        </div>

        <div className='print-section no-break my-4'>
          <Chart
            width='700px'
            height='500px'
            chartType='ColumnChart'
            data={supplyChartData}
            options={{
              title: 'Day wise dispatch (Kg)',
              series: {
                0: {color: '#9966FF'},
              },
              hAxis: {title: 'Day'},
              vAxis: {title: 'Amount (kg)'},
              bar: {groupWidth: '70%'},
              legend: 'none',
            }}
          />
        </div>
      </div>

      <div className='print-section no-break my-4'>
        <h4 className='text-lg font-bold'>Recommendations</h4>
        <ul className='list-disc pl-5'>
          <li>Consider increasing the collection frequency for high-demand items.</li>
          <li>Explore new suppliers for better pricing on collected materials.</li>
          <li>Enhance marketing strategies to raise awareness about recycling.</li>
        </ul>
      </div>

      <div className='print-section no-break my-4'>
        <h4 className='text-lg font-bold'>Glossary</h4>
        <p>
          <strong>Open Inventory:</strong> Inventory available at the start of the month.
        </p>
        <p>
          <strong>Closing Inventory:</strong> Inventory remaining at the end of the month.
        </p>
      </div>

      <footer className=' print-section text-center text-sm text-gray-600 border-t-2 border-gray-300 pt-4 mt-8'>
        {/* Add footer content here */}
      </footer>

      <style>
        {`




                @media print {
                    /* General print styles */
                    .print-container {
                        width: 100% !important;
                        max-width: none !important;
                        margin: 0 !important;
                        padding: 20px !important;
                    }

                    /* Prevent unwanted page breaks */
                    .no-break {
                        page-break-inside: avoid !important;
                    }

                    /* Ensure charts fit on pages */
                    .charts-container {
                        margin: 20px 0 !important;
                        page-break-before: auto !important;
                    }

                    /* Adjust chart sizes for print */
                    .print-section Chart {
                        max-height: 400px !important;
                        margin-bottom: 20px !important;
                    }

                    /* Ensure proper page breaks between sections */
                    .print-section {
                        margin: 20px 0 !important;
                        page-break-inside: avoid !important;
                    }

                    /* Remove unnecessary backgrounds and borders for print */
                    @page {
                        margin: 0.5in;
                    }

                    /* Ensure text is readable when printed */
                    body {
                        font-size: 12pt !important;
                        line-height: 1.3 !important;
                    }

                    /* Ensure tables look good in print */
                    table {
                        page-break-inside: avoid !important;
                        border-collapse: collapse !important;
                    }

                    /* Ensure charts don't get cut off */
                    svg {
                        max-height: 400px !important;
                    }
                }
                    @media print {
    .chart-container {
        width: 100% !important; // Ensure the chart takes full width
    }
    svg {
        width: 100% !important; // Ensure SVGs are properly sized
        height: auto !important;
    }
}

                `}
      </style>
    </div>
  )
}

export default MonthlyReport
