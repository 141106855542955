import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {label: 'S.N', value: 'id'},
  {
    label: 'Name',
    value: 'certificationName',
  },
  {
    label: 'Number',
    value: 'certificationNo',
  },
  {
    label: 'Issuing Authority.',
    value: 'issuingAuthorities',
  },
  {
    label: 'Logo',
    value: 'avatar',
  },
  {
    label: 'Issued On',
    value: 'issuedOn',
  },
  {
    label: 'Valid Till',
    value: 'validTill',
  },
  {
    action: ['edit', 'delete'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
