/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  data?: any
}

const ListsWidget2: React.FC<Props> = ({className, data}) => {
  var newData =
    data &&
    Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }))
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Top 5 Waste Collectors</span>
          {/* <span className='text-muted fw-semibold fs-7'>Top 5 Waste Collectors</span> */}
        </h3>
        {/* <h3 className='card-title fw-bold text-dark'>Analytics</h3>
        <span className='text-muted fw-semibold fs-7'>890,344 Sales</span> */}
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {newData.map((eachData, eachInd) => (
          <div key={eachInd + 1 + ''} className='d-flex align-items-center mb-7'>
            {/* begin::Avatar */}
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/icons8-waste-60.png')} className='' alt='' />
            </div>
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className='flex-grow-1'>
              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                {eachData.name || ''}
              </a>
              <span className='text-muted d-block fw-semibold'>{eachData.value || ''} kg</span>
            </div>
            {/* end::Text */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget2}
