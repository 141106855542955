import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  isAdmin: boolean
  isPartner: boolean
  isFranchise: boolean
  isAuditor: boolean
  isSponsor: boolean
  setisAdmin: any
  setisSponsor: any
  setisPartner: any
  setisFranchise: any
  setisAuditor: any
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  isAdmin: false,
  isPartner: false,
  isFranchise: false,
  isAuditor: false,
  isSponsor: false,
  setisAdmin: () => {},
  setisPartner: () => {},
  setisSponsor: () => {},
  setisFranchise: () => {},
  setisAuditor: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [isSponsor, setisSponsor] = useState(false)
  const [isAdmin, setisAdmin] = useState<any>(false)
  const [isPartner, setisPartner] = useState<any>(false)
  const [isFranchise, setisFranchise] = useState<any>(false)
  const [isAuditor, setisAuditor] = useState<any>(false)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      setisAdmin(auth?.data?.userType === 'ADMIN')
      setisSponsor(auth?.data?.userType === 'SPONSOR')
      setisPartner(auth?.data?.userType === 'CLIENT')
      setisFranchise(auth?.data?.userType === 'FRANCHISE')
      setisAuditor(auth?.data?.userType === 'AUDITOR')
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setisAdmin(false)
    setisPartner(false)
    setisFranchise(false)
    setisAuditor(false)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        isAdmin,
        isSponsor,
        isPartner,
        isFranchise,
        isAuditor,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        setisAdmin,
        setisPartner,
        setisFranchise,
        setisSponsor,
        setisAuditor,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {
    auth,
    logout,
    setCurrentUser,
    setisAdmin,
    setisAuditor,
    setisFranchise,
    setisPartner,
    setisSponsor,
  } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data}: any = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
            setisAdmin(data?.userType === 'ADMIN')
            setisPartner(data?.userType === 'CLIENT')
            setisSponsor(data?.userType === 'SPONSOR')
            setisFranchise(data?.userType === 'FRANCHISE')
            setisAuditor(data?.userType === 'AUDITOR')
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (auth && auth?.data?.userId) {
      requestUser(auth.data.userId)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
