import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {label: 'S.N', value: 'id'},
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Contact',
    value: 'personalDetails[mobile]',
  },
  {
    label: 'Company Name',
    value: 'companyDetails[name]',
  },
  {
    label: 'Country',
    value: 'address[country]',
  },
  {
    label: 'Personal ID',
    value: 'POE_null',
  },
  {
    label: 'Corporate ID',
    value: 'POI_null',
  },
  /*  {
    label: 'Client',
    value: 'nextDue',
  }, */
  {
    action: ['edit', 'QR', 'delete'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
