import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = (isFranchise = false) => [
  {label: 'S.N', value: 'id'},
  {
    label: 'Name',
    value: 'name',
  },
  /* {
    label: 'Birthday',
    value: 'birthday',
  },
  {
    label: 'Gender',
    value: 'gender',
  }, */
  /* {
    label: 'Email Address',
    value: 'email',
  }, */
  {
    label: 'Type',
    value: 'customerType',
  },
  {
    label: 'ID Number',
    value: 'mobile',
  },
  {
    label: 'KYC',
    value: 'bussinessPermit',
  },
  {
    label: 'Supplied',
    value: 'totalDepositedQuantity',
  },
  {
    label: 'Sankey',
    value: 'sankey',
  },
  {
    action: ['qr', ...((isFranchise && ['edit', 'delete']) || [])],
  },
  /*  {
    label: 'Joining Date',
    value: 'joinDate',
  },
  {
    label: 'Current Level',
    value: 'currentLevel',
  },
  {
    label: 'Points Earned',
    value: 'pointEarned',
  },
  {
    label: 'Redeemed',
    value: 'pointRedeemed',
  }, */
]

const roleColumns = (isFranchise = false) => {
  return columnGenerators(header(isFranchise))
}
export {roleColumns}
