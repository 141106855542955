/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  data?: any
}

const ListsWidget6: React.FC<Props> = ({className, data}) => {
  var newData = data
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>My Supply Chain</h3>
        {/*  <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {/* begin::Item */}
        {newData &&
          newData?.slice(0, 4)?.map((eachData, eachIndex) => (
            <div
              key={eachIndex + 1 + ''}
              className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'
            >
              {/* begin::Icon */}
              <span className=' text-warning me-5'>
                <KTIcon iconName='abstract-26' className='text-warning fs-1 me-5' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {eachData.name}
                </a>
                {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold text-warning py-1'>
                {eachData?.diversionCapacity?.filter((x) => x?.name === 'Rigid')[0]?.capacity}
              </span>
              {/* end::Lable */}
            </div>
          ))}
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget6}
