import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {useFetchCommon} from '../../../../../../_metronic/helpers/crud-helper/useQuery'
import Certificate from './Certificate'
import DatePicker from 'react-datepicker'
import {useState} from 'react'

const initialState = {
  date: '',
  facility: '',
}
const QualityList = () => {
  const [userSelectedData, setUserSelectedData] = useState<any>(initialState)
  const [searchResult, setSearchResult] = useState(false)
  const dateFormated = userSelectedData?.date?.split('T') || ''
  const newFormatedDate = dateFormated?.[0]?.split('-')
  const connectedData = +newFormatedDate[1] + '/' + newFormatedDate?.[0]
  const {responseData: facilityTypeList} = useFetchCommon({
    api: 'users?page=0&size=10&type=PICKUP_POINT',
    label: ['personalDetails', 'name'],
    isDropDown: true,
  })
  /* FOR THE API */
  const {responseData: analyticsMonth} = useFetchCommon({
    api: `admin/pickupPoints/analytics/${userSelectedData.facility}?month=${connectedData}`,
    label: ['personalDetails', 'name'],
    isDropDown: false,
    isEnabled: searchResult,
  })
  const {responseData: inventory} = useFetchCommon({
    api: `admin/pickupPoints/${userSelectedData.facility}/inventory?month=${connectedData}`,
    label: ['personalDetails', 'name'],
    isDropDown: false,
    isEnabled: searchResult,
  })
  const {responseData: analytics} = useFetchCommon({
    api: `analytics/${userSelectedData.facility}?month=${connectedData}`,
    label: ['personalDetails', 'name'],
    isDropDown: false,
    isEnabled: searchResult,
  })

  const handleChange = (name, value) => {
    setSearchResult(false)
    setUserSelectedData({...userSelectedData, [name]: value})
  }

  // console.log({analyticsMonth, inventory, analytics})
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear()
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`

    return <span title={tooltipText}>{shortMonth}</span>
  }
  console.log({data: userSelectedData.date}, 'FROM THE SELECTED DATA')

  return (
    <>
      <div className={`grid grid-cols-4 gap-3 my-2`}>
        <>
          <div className=''>
            <label className='form-label fs-6 fw-bold'>Facility</label>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => handleChange('facility', e.target.value)}
              value={userSelectedData.facility || ''}
            >
              {[{value: '', label: 'Select one'}, ...facilityTypeList].map(
                (eachOption, eachInd) => (
                  <option key={eachInd + 1 + ''} value={eachOption.value}>
                    {eachOption.label}
                  </option>
                )
              )}
            </select>
          </div>
          <div>
            <label className='form-label fs-6 fw-bold'>Month</label>
            <br />
            <DatePicker
              // dateFormat='MM/yyyy'
              placeholderText={`Select Month`}
              className='form-control form-control-solid w-250px ps-14'
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
              show
              selected={userSelectedData.date || null}
              // onChange={(date) => handleChange('date', new Date(date))}
              onChange={(date) => {
                // Normalize to midnight local time
                const normalizedDate = new Date(date.getFullYear(), date.getMonth(), 1)

                // Convert to ISO 8601 format in UTC
                const utcDate = new Date(
                  Date.UTC(
                    normalizedDate.getFullYear(),
                    normalizedDate.getMonth(),
                    normalizedDate.getDate()
                  )
                )
                console.log({utcDate})
                handleChange('date', utcDate.toISOString())
              }}
            />{' '}
          </div>
          <div
            className='flex'
            style={{
              marginTop: '27px',
            }}
          >
            <button
              type='button'
              style={{backgroundColor: '#12B886'}}
              onClick={() => setSearchResult(true)}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
              disabled={!userSelectedData.date || !userSelectedData.facility}
            >
              Apply
            </button>
            &nbsp;
            <button
              type='button'
              style={{backgroundColor: '#333333'}}
              // onClick={handleExport}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
              disabled
              // disabled={dataToRender && dataToRender.length === 0}
            >
              Download
            </button>
          </div>
        </>
      </div>
      {
        /* Object.keys(inventory).length > 0 && */ <Certificate
          inventory={inventory}
          graphData={analytics}
          analyticsMonth={analyticsMonth?.[0] || {}}
          month={connectedData || ''}
        />
      }
    </>
  )
}

const MonthlyReportWrapper = () => (
  <QueryRequestProvider initialValue={{enabled: false}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <QualityList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MonthlyReportWrapper}
