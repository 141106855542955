import React, {useEffect, useState} from 'react'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'

export default function AutoCompleteAddress({
  formik = {},
  address,
  setAddress,
  location,
  setlocation,
}) {
  return (
    <LocationInput
      formik={formik}
      address={address}
      setAddress={setAddress}
      location={location}
      setlocation={setlocation}
    />
  )
}

function LocationInput({address, formik, setAddress, location, setlocation}: any) {
  const handleChangeAddress = (newAddress) => {
    setAddress(newAddress)
  }
  const handleSelectAddress = (newAddress) => {
    setAddress(newAddress)
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        if (Object.keys(formik).length > 0) {
          formik?.setFieldValue('longitute', parseFloat(latLng.lng))
          formik?.setFieldValue('latitute', parseFloat(latLng.lat))
          // trigger validation for specific field
          // formik.validateField('latitute')
          formik?.validateField('longitute')
        }
        setlocation(latLng)
      })
      .catch((error) => console.error('Error', error))
  }
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChangeAddress}
      onSelect={handleSelectAddress}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input form-control form-control-solid mb-3 mb-lg-0',
            })}
          />
          <div className='autocomplete-dropdown-container'>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                : {backgroundColor: '#ffffff', cursor: 'pointer'}
              return (
                <div
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
