import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {allcountryList, isNotEmpty} from '../../../../../../_metronic/helpers'
import {User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, getUserById, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {errorToast, successToast} from '../../../../../../_metronic/helpers/components/Toaster'
import {useQuery} from 'react-query'
import UploadImage from '../../../../../../_metronic/helpers/components/ImageUpload'
import Select from 'react-select'
import {useFetchCommon} from '../../../../../../_metronic/helpers/crud-helper/useQuery'
import {useAuth} from '../../../../auth'
import CountryDropDown from '../../../../../../_metronic/helpers/countryDropDown'
import AutoCompleteAddress from '../../../../../../_metronic/helpers/components/AutoCompletePlace'

type Props = {
  isUserLoading: boolean
  user?: User | any
}

export const mapDataForUser = {
  COLLECTION_TYPE: 'Collection Point',
  MRF: 'Material Recovery Facility',
  AGGREGATOR: 'Private Aggregator',
  CONVERTER: 'End Customer',
  DIVERSION_FACILITY: 'Recycling Facility',
}

const editUserSchema = (data) =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    // proofOfIdentity_num: Yup.string().required('Tax Identification Number is required'),
    rigid: Yup.string().required('Rigid required'),
    country: Yup.string().required('Country required'),
    state: Yup.string().required('state required'),
    city: Yup.string().required('city required'),
    inCategoryId: Yup.string().required('Select Facility type'),

    // auditorStaff: Yup.string().required('Auditor Staff required'),
    // weightScale: Yup.string().required('Weight Scale required'),
    latitute: Yup.number().required('latitute required'),
    longitute: Yup.number().required('longitute required'),
    // proofEstablishment_num: Yup.string().required('Business Identification Number required'),
    ...((data.length &&
      data?.some((item: {label: string; value: string}) => item.value === 'CONVERTER') &&
      {}) || {
      facilityManager: Yup.string().required('Facility Manager required'),
      facilityStaff: Yup.string().required('Mobile App Users'),
    }),

    // address: Yup.string()
    //   .min(3, 'Minimum 3 symbols')
    //   .max(50, 'Maximum 50 symbols')
    //   .required('Address is required'),
    // mobileNumber: Yup.string()
    //   .min(3, 'Minimum 3 symbols')
    //   .max(15, 'Maximum 50 symbols')
    //   .required('Mobile Number is required'),
  })

const UnitMeasurementModalForm: FC<Props> = ({user = {}, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const filterSpecificData = (data, name, key = 'docType', displayData = 'docUrl') => {
    return data?.filter((x) => x[key] === name)?.[0]?.[displayData] || ''
  }

  const [address, setAddress] = useState('')
  const [location, setlocation] = useState<any>({})

  useEffect(() => {
    if (Object.keys(user).length > 0 && user?.address?.street) {
      setAddress(user?.address?.street)
      setlocation({lat: user?.address?.latitute, lng: user?.address?.longitute})
    }
  }, [user])
  const [userForEdit] = useState<any>({
    ...user,
    country: user?.address?.country || '',
    state: user?.address?.state || '',
    city: user?.address?.city || '',
    auditorStaff: user?.auditorId || '',
    weightScale: user?.weightScale?.id || '',
    name: user?.personalDetails?.name || '',
    address: user?.address?.street || '',
    mobileNumber: user.mobileNumber || '',
    inCategoryId: user?.facilityType?.[0] || '',
    countryCode: user?.address?.country || '',
    latitute: (user?.address?.latitute || '') + '' || '',
    longitute: (user?.address?.longitute || '') + '' || '',
    facilityManager: user?.facilityManager?.id || '',
    facilityStaff: user?.facilityStaff?.[0]?.id || '',
    proofOfIdentity: filterSpecificData(user?.kycDocument, 'POE'),
    proofEstablishment_num: filterSpecificData(user?.kycDocument, 'POE', 'docType', 'docNumber'),
    proofOfIdentity_num: filterSpecificData(user?.kycDocument, 'POI', 'docType', 'docNumber'),
    proofEstablishment: filterSpecificData(user?.kycDocument, 'POI'),
    proofOfFacility: filterSpecificData(user?.kycDocument, 'POF'),
    rigid: filterSpecificData(user?.diversionCapacity, 'Rigid', 'name', 'capacity'),
    flexible: filterSpecificData(user?.diversionCapacity, 'Flexible', 'name', 'capacity'),
  })

  const [selectedThirdCertificationDropdown, setselectedThirdCertificationDropdown] = useState<any>(
    /* user?.facilityStaff?.map((x) => ({...x, label: x.name || x})) || */ []
  )
  useEffect(() => {
    if (user?.certifications?.length) {
      setselectedThirdCertificationDropdown(
        user.certifications.map((x) => ({
          value: x.id,
          label: x.certificationName,
        }))
      )
    }
  }, [user.certifications])

  const {auth} = useAuth()
  const hideForm = auth?.data?.userType === 'SMART_CENTRE' || false

  const [assignHubPlastic, setAssignHubPlastic] = useState([])
  const [userList, setUserList] = useState([])
  const [finishedGoodsList, setFinishedGoodsList] = useState<any>([])
  const [selectedInCategoryDropdown, setselectedInCategoryDropdown] = useState(
    /*  user?.facilityType?.map((x) => ({...x, label: x?.name || x})) ||  */ []
  )

  useEffect(() => {
    if (user?.facilityType) {
      console.log({ttt: user.facilityType})
      const changeData = user.facilityType.map((x) => ({
        label: mapDataForUser[x?.name || x],
        value: x.name || x,
      }))
      setselectedInCategoryDropdown(changeData)
    }
  }, [user])

  const [rawMaterialList, setRawMaterialList] = useState<any>([])

  const {responseData: facilityTypeList} = useFetchCommon({api: 'diversionDetails'})
  const {responseData: userListAPi} = useFetchCommon({
    api: 'users?page=0&size=10&type=PICKUP_POINT_STAFF',
    isDropDown: false,
  })
  const {responseData: diversionDetails} = useFetchCommon({
    api: 'materialProcesses?processType=DIVERSION',
  })
  const {responseData: certificationsLists} = useFetchCommon({
    api: 'certifications?status=ACTIVE',
    isDropDown: false,
  })
  const {responseData: auditorsList} = useFetchCommon({api: 'auditors', isDropDown: false})
  const {responseData: weightScaleList} = useFetchCommon({
    api: 'weightscales?status=ACTIVE',
    label: ['model'],
  })

  const certificationListsss =
    certificationsLists?.map((x) => {
      return {
        label: x.certificationName,
        value: x.id,
      }
    }) || {}

  const userListsss =
    userListAPi?.map((x) => {
      return {
        label: x.personalDetails.name,
        value: x.id,
      }
    }) || []

  const dropdownFacility =
    facilityTypeList?.facilityTypes?.map((x) => {
      return {
        label: mapDataForUser[x] || '',
        value: x,
      }
    }) || []

  const {data: response} = useQuery(
    'process',
    () => getUserById(null, 'users?page=0&size=10&type=FRANCHISE'),
    {
      cacheTime: 0,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
      enabled: true, // Set this to true to run the query once
    }
  )

  const [auditList, setAuditList] = useState<any>([])
  useEffect(() => {
    if (auditorsList?.length > 0) {
      // console.log('first')
      setAuditList(
        auditorsList.map((x) => {
          return {
            label: x.personalDetails.name,
            value: x.id,
          }
        })
      )
    }
  }, [auditorsList])

  useEffect(() => {
    if (response?.length) {
      const tempAllCategories = response.map((eachRes) => {
        return {
          label: eachRes?.personalDetails?.name || '',
          value: eachRes.id,
        }
      })
      setAssignHubPlastic(tempAllCategories)
    }
  }, [response])

  const {data: resp} = useQuery(
    'user_list',
    () => getUserById(null, 'certifications?page=0&size=10&status=ACTIVE'),
    {
      cacheTime: 0,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
      enabled: true, // Set this to true to run the query once
    }
  )

  useEffect(() => {
    if (resp?.length) {
      const tempAllCategories = resp.map((eachRes) => {
        return {
          label: eachRes?.certificationName || '',
          value: eachRes.id,
        }
      })
      setUserList(tempAllCategories)
      setFinishedGoodsList(tempAllCategories)
    }
  }, [resp])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [selectedOutCategoryDropdown, setselectedOutCategoryDropdown] = useState(
    user?.facilityStaff?.map((x) => ({...x, label: x.name || x})) || []
  )

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema(selectedInCategoryDropdown),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const filterUserList: any = userListsss.filter(
          (x: any) => x.value === values.facilityManager
        )
        const payload = {
          password: '',
          pprsCertificate: values.pprsCertificate,
          isoCertificate: values.isoCertificate,
          firstName: values.name,
          mobile: values.mobileNumber,
          middleName: '',
          lastName: '',
          userType: 'PICKUP_POINT',
          customerType: 'CORPORATE',
          address: {
            country: values.country,
            street: address || '',
            city: values.city,
            state: values.state,
            latitute: values.latitute,
            longitute: values.longitute,
          },
          kycDocument: [
            {
              docUrl: values?.proofEstablishment,
              docType: 'POE',
              docNumber: values?.proofEstablishment_num,
            },
            {
              docUrl: values?.proofOfIdentity,
              docType: 'POI',
              docNumber: values?.proofOfIdentity_num,
            },
            {
              docUrl: values?.proofOfFacility,
              docType: 'POF',
            },
          ],
          facilityType: selectedInCategoryDropdown.map((x: any) => x?.value || x),
          diversionType: values.diversionType,
          diversionCapacity: isContain
            ? [
                {
                  id:
                    diversionDetails?.diversionCapacities?.filter((x) => x?.name === 'Rigid')[0]
                      ?.id || '',
                  name: 'Rigid',
                  capacity: values.rigid,
                },
                {
                  id:
                    diversionDetails?.diversionCapacities?.filter((x) => x?.name === 'Flexible')[0]
                      ?.id || '',
                  name: 'Flexible',
                  capacity: values.flexible,
                },
              ]
            : [],
          facilityManager: {
            id: values.facilityManager,
            name: filterUserList[0]?.label || '',
          },
          franchiseId: values.franchiseId,
          certifications: selectedThirdCertificationDropdown?.map((x) => {
            return x.value
          }),
          weightScale: values.weightScale,
          auditorId: values.auditorStaff,
          auditorName: auditList.filter((x) => x.value === values.auditorStaff)?.[0]?.label || '',
          facilityStaff: selectedOutCategoryDropdown.map((x) => {
            return {
              id: x.id || x.value,
              name: x.label,
            }
          }),
        }
        if (isNotEmpty(values.id)) {
          await updateUser(payload, `users/${values.id}/update`)
          successToast('Modified')
          cancel(true)
        } else {
          await createUser(payload, 'user/register')
          successToast('Added')
          cancel(true)
        }
      } catch (ex) {
        errorToast('Somethig Went Wrong')
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const inputForm = ({label, name, placeholder, required = true}) => {
    return (
      <div className='fv-row mb-7'>
        <label className={`${required ? 'required' : ''} fw-bold fs-6 mb-2`}>{label}</label>
        <input
          placeholder={placeholder}
          {...formik.getFieldProps(name)}
          type='text'
          name={name}
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched[name] && formik.errors[name]},
            {
              'is-valid': (required && formik.touched[name] && !formik.errors[name]) || '',
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched[name] && formik.errors[name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[name]}</span>
            </div>
          </div>
        )}
      </div>
    )
  }
  const makeSelectDropDown = (name = '', options: any = [], showTick = true) => {
    return (
      <>
        <select
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched[name] && formik.errors[name]},
            {
              'is-valid': (showTick && formik.touched[name] && !formik.errors[name]) || '',
            }
          )}
          {...formik.getFieldProps(name)}
        >
          <option value=''>Select One...</option>
          {options.map((eachOption, eachInd) => (
            <option key={eachInd + 1 + ''} value={eachOption.value}>
              {eachOption.label}
            </option>
          ))}
        </select>
        {formik.errors[name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[name]}</span>
            </div>
          </div>
        )}
      </>
    )
  }

  const handleSelect = (e, options, name, action) => {
    if (name === 'inCategoryId') {
      setselectedInCategoryDropdown(e)
    } else if (name === 'certifications') {
      setselectedThirdCertificationDropdown(e)
    } else {
      setselectedOutCategoryDropdown(e)
    }
    if (action.removedValue?.length) {
      formik.setFieldValue(
        name === 'inCategoryId'
          ? 'inMaterials'
          : name === 'certifications'
          ? 'certifications'
          : 'outMaterials',
        []
      )
      setRawMaterialList([])
    } else if (action?.removedValue) {
      const newFilteredData: any = (
        name === 'inCategoryId' ? rawMaterialList : finishedGoodsList
      )?.filter((x) => x.categoryId !== action.removedValue.id)
      const filterInOutCategory = formik.values[
        name === 'inCategoryId' ? 'inMaterials' : 'outMaterials'
      ]?.filter((x) => x.categoryId !== action.removedValue.id)
      formik.setFieldValue('inMaterials', filterInOutCategory)
      name === 'inCategoryId'
        ? setRawMaterialList(newFilteredData)
        : setFinishedGoodsList(newFilteredData)
    } else {
      const value = e[e.length - 1]?.value || ''
      const filter = options?.filter((x) => x.value === value)
      if (name === 'inCategoryId' && filter.length) {
        formik.setFieldValue('inCategoryName', filter[0]?.name || '')
      } else if (name === 'certifications' && filter.length) {
        formik.setFieldValue('certifications', filter[0]?.name || '')
      } else {
        if (filter.length) {
          formik.setFieldValue('outCategoryName', filter[0]?.name || '')
        }
      }
      formik.setFieldValue([name], value)
    }
  }

  const multiSelect = (name = '', options: any = [], value) => {
    return (
      <>
        <Select
          isMulti
          name={name}
          options={options}
          className='basic-multi-select'
          classNamePrefix='select'
          value={value}
          onChange={(selected, action) => handleSelect(selected, options, name, action)}
        />
        {formik.errors[name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[name]}</span>
            </div>
          </div>
        )}
      </>
    )
  }

  // const dropdownDiverison =
  //   diversionDetails?.diversionTypes?.map((x) => {
  //     return {
  //       label: x,
  //       value: x,
  //     }
  //   }) || []

  const assignData = Object.assign([], selectedInCategoryDropdown)
  const isContain = JSON.stringify(assignData).includes('Recycling Facility')
  // console.log({
  //   selectedInCategoryDropdown,
  //   test: selectedInCategoryDropdown?.filter((x: any) => x.label === 'DIVERSION_FACILITY'),
  //   forrr: formik.errors,
  //   forr: formik.values,
  //   hello: selectedInCategoryDropdown?.some(
  //     (item: {label: string; value: string}) => item?.value === 'CONVERTER'
  //   ),
  // })

  console.log({address, location, hell0: formik.values})
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='grid grid-cols-2 gap-2'>
          {inputForm({label: 'Business Name', name: 'name', placeholder: 'Enter Business Name'})}
          {hideForm && (
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2 required'>EXN MEMBER</label>
              {makeSelectDropDown('franchiseId', assignHubPlastic)}
            </div>
          )}
        </div>
        <div className={`grid grid-cols-2 gap-2`}>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Facility Type</label>
            {multiSelect('inCategoryId', dropdownFacility, selectedInCategoryDropdown)}
          </div>
          {(selectedInCategoryDropdown?.filter((x: any) => x.label === 'Recycling Facility')
            ?.length && (
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2 required'>Diversion Type</label>
              {makeSelectDropDown('diversionType', diversionDetails)}
            </div>
          )) ||
            null}
        </div>
        {/* {(isContain && (
          <div className='grid grid-cols-2 gap-2'>
            {inputForm({
              label: 'Rigid',
              name: 'rigid',
              placeholder: 'Enter diversion capacity in tons per annum',
            })}
            <div className='fv-row mb-7'>
              {inputForm({
                label: 'Flexible',
                name: 'flexible',
                placeholder: 'Enter diversion capacity in tons per annum',
              })}
            </div>
          </div>
        )) ||
          null} */}
        <div className='grid grid-cols-2 gap-2'>
          {inputForm({
            label: 'Annual Processing Capacity (Matric Tons)',
            name: 'rigid',
            placeholder: 'Enter only values',
          })}
          <CountryDropDown showState showCity name='country' formik={formik} showNameOnly />
        </div>
        <div className='grid'>
          <div className='fv-row mb-7'>
            <label className=' fw-bold fs-6 mb-2'>Street</label>
            <AutoCompleteAddress
              address={address}
              setAddress={setAddress}
              location={location}
              formik={formik}
              setlocation={setlocation}
            />
            {formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2'>
          {inputForm({label: 'Latitude', name: 'latitute', placeholder: 'Enter Latitude'})}
          {inputForm({label: 'Longitude', name: 'longitute', placeholder: 'Enter Longitude'})}
        </div>
        <div className='grid grid-cols-2 gap-2'>
          {inputForm({
            required: false,
            label: 'Business Identification Number',
            name: 'proofEstablishment_num',
            placeholder: 'Enter Business Identification Number',
          })}
          {inputForm({
            required: false,
            label: 'Tax Identification Number',
            name: 'proofOfIdentity_num',
            placeholder: 'Enter Tax Identification Number',
          })}
        </div>
        {selectedInCategoryDropdown?.some(
          (item: {label: string; value: string}) => item?.value === 'CONVERTER'
        ) ? (
          ''
        ) : (
          <div className='grid grid-cols-2 gap-2'>
            <div className='fv-row mb-7'>
              <label className='fw-bold required fs-6 mb-2'>Facility Manager</label>
              {makeSelectDropDown('facilityManager', userListsss)}
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Mobile App Users</label>
              {multiSelect('facilityStaff', userListsss, selectedOutCategoryDropdown)}
            </div>
          </div>
        )}
        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>Assign Certifications</label>
          {multiSelect('certifications', certificationListsss, selectedThirdCertificationDropdown)}
        </div>
        <div className='grid grid-cols-2 gap-2'>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Assign Auditor</label>
            {makeSelectDropDown('auditorStaff', auditList, false)}
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Assign Weight Scale</label>
            {makeSelectDropDown('weightScale', weightScaleList, false)}
          </div>
        </div>
        <div className='grid grid-cols-3 gap-2'>
          <UploadImage
            name='proofEstablishment'
            required={false}
            formik={formik}
            label='Business Identification'
          />
          <UploadImage
            required={false}
            name='proofOfIdentity'
            formik={formik}
            label='Environmental Permit'
          />
          <UploadImage
            required={false}
            name='proofOfFacility'
            formik={formik}
            label='Tax Identification'
          />
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            style={{backgroundColor: '#333333'}}
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export default UnitMeasurementModalForm
