import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React, {FC, Suspense} from 'react'
import {ReportsMenu} from './ReportsMenu'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../../_metronic/helpers'
import {UserManagementWrapper} from '../data-administration/data-admininstration-list/user-management/userManagementList'
import {WasteCollectorPaymentList} from '../data-administration/data-admininstration-list/waste-collector-payment/wasteCollectorPaymentList'
import {StockWrapper} from '../data-administration/data-admininstration-list/stock/stockList'
import {MonthlyReportWrapper} from '../data-administration/data-admininstration-list/monthly-report/monthlyReportList'
import {InvenotryListsWrapper} from '../data-administration/data-admininstration-list/invenotry/inventoryList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/apps/reports',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsCollectorPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<ReportsMenu />} />
        <Route
          path='inventory'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Annual Report</PageTitle>
              <InvenotryListsWrapper />
            </>
          }
        />
        <Route
          path='stock'
          element={
            <>
              <PageTitle
                description='View the SKU wise inventory' /* breadcrumbs={usersBreadcrumbs} */
              >
                Stock
              </PageTitle>
              {/* <DataAdmininstrationMenu /> */}
              <StockWrapper />
            </>
          }
        />
        <Route
          path='monthly-reports'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Monthly Report</PageTitle>
              {/* <DataAdmininstrationMenu /> */}
              <MonthlyReportWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default ReportsCollectorPage
