import React from 'react'
import {mapDataForUser} from '../../app/modules/apps/data-administration/data-admininstration-list/form-edit-modal/FacilitiesModalForm'
import {generateAddress} from './addressGenarator'
import ImageLightBox from './components/ImageLightBox'
import {configCategoryMap} from './dataExamples'

const configMapData = configCategoryMap

const accessNestedProperty = (obj, path) => {
  return path.split('[').reduce((acc, key) => {
    return acc && acc[key.replace(']', '')]
  }, obj)
}

export const HelloTesting = (passData: any) => {
  const {
    user,
    mapData,
    pathname,
    isPartner,
    itemIdForUpdate,
    assignAuditor,
    setShowMapModal,
    lightBoxRef,
    setshowImageModal,
  } = passData
  if (mapData.includes('[')) return clipText(accessNestedProperty(user, mapData), 30)
  switch (mapData) {
    case 'auditorName':
      return isPartner ? (
        user[mapData] ? (
          clipText(user[mapData], 10)
        ) : (
          <div
            className='text-red-500'
            onClick={async () =>
              pathname === '/apps/plastic-credits/unaudited'
                ? await assignAuditor.mutateAsync(user[mapData])
                : false
            }
          >
            {pathname === '/apps/plastic-credits/unaudited' ? ' Click to assign' : '-'}
          </div>
        )
      ) : (
        user[mapData]
      )
    case 'test':
      return user?.tests?.[0]?.testName || '-'
    case 'B2B':
      return user.wasteSource === mapData ? user.pickupPointName : user?.customerInfo?.name
    case 'BLOCKCHAIN':
    case 'COLLECT':
    case 'PROCESSING':
    case 'SUPPLY':
    case 'RECEIVE':
    case 'DPP':
    case 'PLASTIC_CREDITS':
      return user?.detail?.map((x: any) => (x?.transactionName === mapData ? x.price : ''))
    case 'mapLocation':
      return (
        <div
          onClick={() =>
            setShowMapModal({
              show: true,
              clicked: mapData,
            })
          }
        >
          {user.latitude ? 'click to view' : '-'}
        </div>
      )
    case 'sankey':
      return (
        <div
          onClick={() =>
            setshowImageModal({
              show: true,
              clicked: mapData,
            })
          }
        >
          Click to view
        </div>
      )
    case 'certification_table':
    case 'weightscale_table':
    case 'analytics_table':
    case 'bom':
      return (
        <div
          className={
            (mapData === 'bom' &&
              user?.bomDetails?.length === 0 &&
              `cursor-not-allowed opacity-25`) ||
            ''
          }
          style={{
            color: (user?.bomDetails?.length && '#12B886') || '',
          }}
          onClick={() =>
            mapData === 'bom'
              ? user?.bomDetails?.length
                ? setshowImageModal({
                    show: true,
                    clicked: mapData,
                  })
                : ''
              : setshowImageModal({
                  show: true,
                  clicked: mapData,
                })
          }
        >
          Click to view
        </div>
      )
    case 'pickupointId':
      return user[mapData].map((x) => x?.pickupPointName)
    case 'transactionType':
      return (
        <span
          className={`badge badge-light-${
            user.transactionType !== 'DR' ? 'primary' : 'success'
          } fs-7 fw-bold`}
        >
          {user.transactionType}
        </span>
      )
    case 'rigid-badge':
      return (
        <span className={`badge badge-primary-success fs-7 fw-bold`}>
          {user?.diversionCapacity?.filter((x) => x?.name?.toLowerCase() === 'rigid')?.[0]
            ?.capacity * 1000 || '-'}
        </span>
      )
    case 'rigid':
      return (
        <span className={`badge badge-light-primary fs-7 fw-bold`}>
          {user?.diversionCapacity?.filter((x) => x?.name?.toLowerCase() === mapData)?.[0]
            ?.capacity * 1000 || '-'}
        </span>
      )
    case 'flexible':
      return user?.diversionCapacity?.filter((x) => x?.name === mapData)?.[0]?.capacity || '-'
    case 'facilityType':
      // return user?.facilityType?.length
      //   ? user.facilityType
      //       .map((type) => mapDataForUser[type] || type) // Map with matched data, fallback to original
      //       .join(' , ')
      //   : user?.facilityType?.toUpperCase()
      return user?.facilityType?.length
        ? user.facilityType.map((type) => (
            <React.Fragment key={type}>
              {mapDataForUser[type] || type}
              <br />
            </React.Fragment>
          ))
        : user?.facilityType?.toUpperCase()
    case 'categoryMap':
      return <div>{configMapData[user.categoryId] || ''}</div>
    case 'format':
      return user.companyDetails.format || 'OWN'
    case 'personalDetail':
      return (
        <div>
          <div>{user.personalDetails?.mobile}</div>
          <div className='text-muted fw-semibold text-muted d-block fs-7'>
            {user.personalDetails?.email}
          </div>
        </div>
      )
    case 'contactDetail':
      return (
        <div>
          <div>{user.companyDetails?.mobile}</div>
          <div className='text-muted fw-semibold text-muted d-block fs-7'>
            {user.companyDetails?.email}
          </div>
        </div>
      )
    case 'icon':
    case 'url':
    case 'logo':
    case 'paymentProof':
      return (
        <div className='symbol symbol-circle'>
          {user?.[mapData] ? (
            <img
              src={user?.[mapData] || ''}
              alt={user.name}
              onClick={() =>
                lightBoxRef?.current?.open(`${user?.[mapData] || ''}`, itemIdForUpdate)
              }
            />
          ) : (
            ''
          )}
          {user.transactionId && mapData && <ImageLightBox ref={lightBoxRef} />}
        </div>
      )
    case 'categoryIcon':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={`${user?.orderDetails?.[0]?.categoryIcon || ''}`}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    case 'inputQuantity':
      return (user?.inputQuantity || '0') + ' kg'
    case 'processType':
      return (
        <span
          className={`${user?.processType === 'DIVERSION' ? 'badge badge-light-success' : ''} `}
        >
          {user?.processType || ''}
        </span>
      )
    case 'processName':
      return (
        <span className={`badge badge-light-info fs-7 fw-bold`}>{user?.processName || ''}</span>
      )
    case 'year-01':
    case 'year-02':
    case 'year-03':
    case 'year-04':
    case 'year-05':
    case 'year-06':
    case 'year-07':
    case 'year-08':
    case 'year-09':
    case 'year-10':
    case 'year-11':
    case 'year-12':
      const transformedData = {}
      const splitMonth = mapData.split('year-')
      Object.keys(user.monthWiseData).forEach((key) => {
        const month = key.split('-')[1] // Extract the MM part
        transformedData[month] = user.monthWiseData[key]
      })
      return transformedData[splitMonth?.[1]] || '0'
    case 'categoryName':
      return (
        <span className={`badge badge-light-info fs-7 fw-bold`}>
          {(user?.orderDetails || user?.orderDetail)?.[0]?.categoryName || user?.categoryName || ''}
        </span>
      )
    case 'targetValue':
      return (
        user?.tests?.map((x) =>
          x?.parameters.map((y) => (
            <span className={`badge badge-light-success fs-7 fw-bold`}>
              {y?.[mapData]}
              <br />
            </span>
          ))
        ) || '-'
      )
    case 'remark':
    case 'quantityDeposit_remark':
      return user?.orderDetails?.[0]?.items
        ?.map((x) => (mapData === 'remark' ? x?.itemName : x.quantity + ' kg') || '')
        .join(', ')
    case 'testName':
      return user?.tests?.map((x) => x?.testName || '').join(', ') || '-'
    case 'parameter':
    // case 'targetValue':
    case 'minimum':
    case 'maximum':
    case 'standard':
      // return user?.tests?.map((x) => x?.parameters.map((y) => y?.[mapData]) || '').join(', ') || '-'
      return (
        user?.tests?.map((x) =>
          x?.parameters.map((y) => (
            <>
              {y?.[mapData]}
              <br />
            </>
          ))
        ) || '-'
      )
    case 'pricesss':
      return user.price + '' || ''
    case 'price':
      return user?.orderDetails?.[0]?.items?.map((x) => x[mapData] || '').join(', ') || '-'
    case 'quantityDeposit':
      const totalQuantity =
        user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) || 0
      return <span className='text-primary fs-7 fw-bold'>{totalQuantity?.toFixed(2) + ' kg'}</span>
    case 'pickup_quantity':
    case 'pickup_quantity_item':
      return (user?.orderDetails || user?.orderDetail)?.[0].items?.map((x: any, ind: number) => (
        // <div className='text-primary fs-7 fw-bold' key={ind + 1 + ''}>
        <div className='text-gray-800 fs-7' key={ind + 1 + ''}>
          {mapData === 'pickup_quantity_item' ? x.itemName : x.quantity?.toFixed(2)}{' '}
          {mapData === 'pickup_quantity' ? 'Kg' : ''}
        </div>
      ))
    case 'bussinessPermit':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            {/*  <img
              alt={user.name}
              className='w-100'
              src={`${user?.kycDocument?.[0]?.docUrl || ''}`}
            /> */}
            <img
              src={`${user?.kycDocument?.[0]?.docUrl || ''}`}
              alt={user.name}
              className='w-100'
              onClick={() =>
                lightBoxRef?.current?.open(
                  `${user?.kycDocument?.[0]?.docUrl || ''}`,
                  itemIdForUpdate
                )
              }
            />
            {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
          </div>
        </div>
      )

    case 'POE_null':
    case 'POI_null':
      const images = user?.kycDocumentList?.[mapData === 'POE_null' ? 0 : 1]?.docUrl || ''
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={images}
              alt={user.name}
              className='w-100'
              onClick={() => lightBoxRef?.current?.open(images, itemIdForUpdate)}
            />
            {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
          </div>
        </div>
      )

    case 'proofEstablishment':
    case 'proofOfIdentity':
    case 'proofOfFacility':
    case 'POF':
    case 'POI':
    case 'POE':
    case 'CLIENT_LOGO':
    case 'personalImage':
    case 'EXN_AGGREMENT':
    case 'SEC_DTI':
      const image = user?.kycDocument?.filter((x) => x?.docType === mapData)?.[0]?.docUrl || ''
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={image}
              alt={user.name}
              className='w-100'
              onClick={() => lightBoxRef?.current?.open(image, itemIdForUpdate)}
            />
            {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
          </div>
        </div>
      )
    case 'pickup_address':
      return clipText(generateAddress(user.pickupInfo?.address))
    case 'address':
      // return generateAddress(user.address || user.pickupInfo?.address)
      const addressConcat = generateAddress(
        user.address || user.pickupInfo?.address || user.dropOffPointInfo?.address
      )
      return (
        <div data-bs-toggle='tooltip' title={addressConcat}>
          {clipText(addressConcat, 30)}
        </div>
      )
    case 'wastage':
      return (
        <span className='badge badge-light-danger fs-7 fw-bold'>
          {user?.wastage?.toFixed(2)} kg
        </span>
      )
    case 'teamSize':
      return <span className=''>{user?.teamSize}</span>
    case 'manager':
      return user?.companyDetails?.name
    case 'geoLocation':
      // return user.address.latitute + ' , ' + user.address.longitute
      return (
        <>
          <div>{user?.address?.latitute}</div>
          <div>{user?.address?.longitute}</div>
        </>
      )
    case 'pickupCompletedAt':
      return (
        (user?.pickupInfo?.pickupCompletedAt &&
          new Date(+user?.pickupInfo?.pickupCompletedAt || new Date()).toLocaleDateString()) ||
        ''
      )
    case 'collectionDate':
    case 'completedAt':
    case 'date':
      return new Date(+user?.[mapData] || new Date()).toLocaleDateString()

    case 'pickupDate':
      return new Date(+user?.pickupInfo?.pickupDate || new Date()).toLocaleDateString()

    case 'customerType':
      return (
        <span
          className={`badge badge-light-${
            user?.[mapData] === 'INDIVIDUAL' ? 'success' : 'primary'
          } fs-7 fw-bold`}
        >
          {user?.[mapData]}
        </span>
      )
    case 'paymentMode':
      return (
        <span
          className={`badge badge-light-${
            user?.[mapData] === 'CASH'
              ? 'info'
              : user?.[mapData] === 'WALLET'
              ? 'primary'
              : 'success'
          } fs-7 fw-bold`}
        >
          {user?.[mapData] || user?.personalDetails?.[mapData]}
        </span>
      )
    case 'street':
      return clipText(user?.address?.street)
    case 'issuedOn':
    case 'validTill':
    case 'issueOn':
      return user?.[mapData] ? (
        <span
          className={`badge badge-light-${
            mapData !== 'issuedOn' ? 'danger' : 'success'
          } fs-7 fw-bold`}
        >
          {new Date(user?.[mapData] || new Date()).toLocaleDateString()}
        </span>
      ) : (
        '-'
      )
    case 'year':
      return new Date(+user?.[mapData] || new Date()).toLocaleDateString(undefined, {
        year: 'numeric',
      })
    case 'country':
      return user?.pickupPointInfo?.address?.country || ''
    case 'batchId':
      return user.createdAt || ''
    case 'createdAt':
    case 'productionDate':
    case 'supplyDate':
      return user?.[mapData] ? new Date(+user?.[mapData] || new Date()).toLocaleDateString() : '-'
    case 'calibratedOn':
    case 'nextDue':
      return (
        <span
          className={`badge badge-light-${
            mapData === 'nextDue' ? 'danger' : 'success'
          } fs-7 fw-bold`}
        >
          {new Date(+user?.[mapData] || new Date()).toLocaleDateString()}
        </span>
      )

    // case 'productionItemDetailsQuantity':
    //   return user['productionItemDetails']?.map((x: any, ind: number) => (
    //     <div key={ind + 1 + ''}>{x.quantity} Kg</div>
    //   ))
    case 'inMaterialDetailsQuantity':
    case 'productionItemDetailsQuantity':
      const quantities = user[
        mapData === 'inMaterialDetailsQuantity' ? 'inputMaterial' : 'productionItemDetails'
      ]
        ?.map(
          (x: any) =>
            x?.quantity ||
            (x?.inputQuantity
              ? typeof parseFloat(x.inputQuantity) === 'number'
                ? parseFloat(x.inputQuantity).toFixed(2)
                : ''
              : '') ||
            ''
        )
        .filter(Boolean) // Remove falsy values (null, undefined, 0, etc.)
        .join(', ')
      // return quantities ? `${quantities?.toFixed(2)} Kg` : ''
      return quantities ? `${quantities} Kg` : ''
    case 'productionId':
      return user.productionId || ''
    case 'city':
      return user?.address?.city
    case 'cityProvince':
      return user?.address?.city + ' , ' + (user?.address?.state || '')
    case 'pickupPointStaffs':
    case 'pickupPointManagers':
      return user[mapData]?.map((x: any, ind: number) => <div key={ind + 1 + ''}>{x}</div> || '')
    case 'plasticType':
      return user?.productionItemDetails?.map((x) =>
        x?.bomDetails?.map((y) => y?.itemName).join(' , ')
      )
    case 'inMaterials':
    case 'outMaterials':
    case 'inputMaterial':
    case 'productionItemDetails':
      return user[mapData]
        ?.map((x: any) => x?.name || x?.itemName || x?.inputMaterialName)
        .filter(Boolean) // Remove falsy values (like null or undefined)
        .join(', ')
    case 'facilityStaff':
      // return user[mapData]?.map(
      //   (x: any, ind: number) =>
      //     (
      //       <div key={ind + 1 + ''}>
      //         {x?.name === null ? '' : x?.name || x?.itemName || x?.inputMaterialName}
      //       </div>
      //     ) || ''
      // )
      return user[mapData]
        ?.map((x: any) => x?.name || x?.itemName || x?.inputMaterialName)
        .filter(Boolean) // Remove falsy values (like null or undefined)
        .join(', ')

    case 'status_unaudited':
      const statusModify = user?.status || ''
      switch (statusModify) {
        case 'CREATED':
          return <span className='badge badge-primary fs-7 fw-bold'>To Be Issued</span>
        case 'APPROVAL_PENDING':
          return <span className='badge badge-danger fs-7 fw-bold'>Audit Query</span>
        case 'UN_APPROVED':
          return <span className='badge badge-warning fs-7 fw-bold'>Under Audit</span>
        default:
          return ''
      }
    case 'status':
      const statusData = user?.[mapData] || user?.personalDetails?.[mapData]
      switch (statusData) {
        case 'quantityDeposit':
          return <span className='text-primary fs-7 fw-bold'>+28%</span>
        case 'Pickup Completed':
        case 'Completed':
        case 'ACCEPTED':
          return <span className='badge badge-light-success fs-7 fw-bold'>{statusData}</span>
        case 'Rejected':
        case 'REJECTED':
          return <span className='badge badge-light-danger fs-7 fw-bold'>{statusData}</span>
        case 'Created':
        case 'CREATED':
          return <span className='badge badge-light-warning fs-7 fw-bold'>{statusData}</span>
        case 'Accepted':
          return <span className='badge badge-light-danger fs-7 fw-bold'>{statusData}</span>
        case 'Pickup Assigned':
          return <span className='badge badge-light-primary fs-7 fw-bold'>{statusData}</span>

        default:
          return user?.[mapData] || user?.personalDetails?.[mapData]
      }
    default:
      return typeof user[mapData] === 'boolean' ? (
        user[mapData] ? (
          <span className='badge badge-light-success fs-7 fw-bold'>YES</span>
        ) : (
          <span className='badge badge-light-danger fs-7 fw-bold'>NO</span>
        )
      ) : typeof user[mapData] === 'number' ? (
        user?.[mapData] === 0 ? (
          <span className='badge badge-light-danger fs-7 fw-bold'>
            {user?.[mapData]?.toFixed()}
          </span>
        ) : (
          user?.[mapData]?.toFixed() || user?.personalDetails?.[mapData]?.toFixed() || ''
        )
      ) : (
        clipText(user?.[mapData], 25) || clipText(user?.personalDetails?.[mapData], 25) || ''
      )
  }
}
function clipText(text, maxLength = 25, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text || '-'
}
