/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {userInfo} from 'os'

type Props = {
  className: string
  data?: any
}

const TablesWidget13: React.FC<Props> = ({className, data = []}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Sale</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Last 10 supply</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Order ID</th>
                <th className='min-w-140px'>Factory Name</th>
                <th className='min-w-120px'>Date</th>
                <th className='min-w-120px'>Waste Collector</th>
                <th className='min-w-120px'>Material</th>
                <th className='min-w-100px'>Quantity</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.length &&
                data?.slice(0, 10).map((eachData, eachIndex) => {
                  return (
                    <tr key={eachIndex + 1 + ''}>
                      <td>
                        <a href='#' className='text-dark  text-hover-primary fs-6'>
                          {eachData.createdAt}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-success text-hover-primary d-block fs-6'>
                          {eachData?.pickupInfo?.name}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-dark  text-hover-primary d-block mb-1 fs-6'>
                          {new Date(eachData?.createdAt || new Date()).toLocaleDateString()}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-dark  text-hover-primary fs-6'>
                          {eachData?.pickupInfo?.centreName}
                        </a>
                      </td>

                      <td>
                        {eachData?.orderDetails?.[0]?.items?.map((x, len) => {
                          return (
                            <span key={len + 1 + ''} className='text-hover-primary d-block fs-6'>
                              {x.itemName}{' '}
                              {eachData?.orderDetails?.[0]?.items?.length - 1 === len ? '' : ', '}
                            </span>
                          )
                        }) || '-'}
                      </td>
                      <td>
                        <span className='badge badge-light-primary fs-8'>
                          {eachData?.orderDetails?.[0]?.items?.map((x, len) => {
                            return (
                              <span
                                key={len + 1 + ''}
                                className='badge badge-light-primary fs-8 fw-bold'
                              >
                                {x.quantity}{' '}
                                {eachData?.orderDetails?.[0]?.items?.length - 1 === len ? '' : ', '}
                              </span>
                            )
                          }) || '-'}
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget13}
