import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {country} from './world/country'
export default function CountryDropDown({
  name = 'country',
  formik,
  showCity = false,
  showState = false,
  showNameOnly = false,
  isReadOnly = false,
}) {
  const [stateList, setStateList] = useState([])
  const [cityFitlerList, setCityFitlerList] = useState([])
  const [tempData] = useState(formik.values)

  const [isLoadingCity, setIsLoadingCity] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (formik.values.country) {
        import('./world/state').then(({state}) => {
          const filteredStateList: any = state.filter(
            (x) => x.country_name === formik.values.country
          )
          setStateList(filteredStateList)
        })
        if (tempData.country !== formik.values.country) {
          formik.setFieldValue('city', '')
          formik.setFieldValue('state', '')
        }
      } else {
        setStateList([])
      }
    }, 500) // Debounce delay (e.g., 500ms)

    return () => clearTimeout(timer) // Cleanup the timer on component unmount or value change
  }, [formik.values.country])

  useEffect(() => {
    if (formik.values.state) {
      setIsLoadingCity(true)
      import('./world/city').then(({city}) => {
        const filteredCityList: any = city.filter(
          (x) => x.country_name === formik.values.country && x.state_name === formik.values.state
        )
        console.log(filteredCityList, 'filteredCityList')
        setCityFitlerList(filteredCityList)
        setIsLoadingCity(false)
        if (tempData.city !== formik.values.city) {
          formik.setFieldValue('city', '')
        }
      })
    } else {
      setStateList([])
    }
  }, [formik.values.state])

  const makeSelectDropDown = (
    name = '',
    options: any = [],
    formik,
    disabled = false,
    loader = false
  ) => {
    return (
      <select
        disabled={disabled || isReadOnly}
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        {...formik.getFieldProps(name)}
      >
        <option value={''}>
          {loader
            ? 'Please wait, .... finding city'
            : formik.values.state && options.length === 0
            ? 'city unavailable for this province'
            : 'Select One'}
        </option>
        {options.map((eachOption, eachInd) => (
          <option
            key={eachInd + 1 + ''}
            value={eachOption.value || eachOption.name || eachOption.key || eachOption.id}
          >
            {eachOption.label || eachOption.name}
          </option>
        ))}
      </select>
    )
  }
  return (
    <>
      <div className='fv-row mb-7'>
        <label className='required fw-bold fs-6 mb-2'>Country</label>
        {makeSelectDropDown(name, country, formik)}
        {formik.touched[name] && formik.errors[name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[name]}</span>
            </div>
          </div>
        )}
      </div>
      {(showState && (
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Province</label>
          {/* {makeSelectDropDown('state', stateList, formik, !formik.values.country)} */}
          {makeSelectDropDown('state', stateList, formik, !formik.values.country)}
          {formik.touched.state && formik.errors.state && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.state}</span>
              </div>
            </div>
          )}
        </div>
      )) ||
        null}
      {(showCity && (
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>City</label>
          {makeSelectDropDown('city', cityFitlerList, formik, !formik.values.state, isLoadingCity)}
          {formik.touched.city && formik.errors.city && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.city}</span>
              </div>
            </div>
          )}
        </div>
      )) ||
        null}
    </>
  )
}
