import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {UsersTable} from '../table/UsersTable'
import {UserEditModal} from '../form-edit-modal/CoomonEditModal'
import {KTCard, initialQueryState} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../auth'
import {getUserById} from '../core/_requests'
import {useFetchCommon} from '../../../../../../_metronic/helpers/crud-helper/useQuery'

const DropOffPointList = () => {
  const {responseData: facilityTypeList} = useFetchCommon({
    api: 'users?page=0&size=10&type=PICKUP_POINT',
    label: ['personalDetails', 'name'],
    isDropDown: true,
  })
  const {updateState} = useQueryRequest()

  useEffect(() => {
    if (facilityTypeList && facilityTypeList?.length) {
      updateState({
        enabled: true,
        initialApi: `purchaseOrders?type=SOURCE&pickupPointId=${facilityTypeList[0].value}`,
        ...initialQueryState,
      })
    }
  }, [facilityTypeList])

  const searchElements = [
    {
      type: 'select',
      value: 'select',
      options: [...facilityTypeList],
    },
  ]
  return (
    <KTCard>
      <UsersListHeader
        showDownload
        // showResetButton={true}
        searchElements={searchElements}
        placeholder='Search Category'
      />
      <UsersTable columnProps={roleColumns} />
      {/* <UserEditModal showViewPage getByIdApi='return/orders/' formName='ReturnOrdersModalForm' /> */}
    </KTCard>
  )
}

const PurchaseOrdersWrapper = () => (
  <QueryRequestProvider initialValue={{enabled: false}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <DropOffPointList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PurchaseOrdersWrapper}
