import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'
export const header = (isFranchise = false) => [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Category',
    value: 'categoryName',
  },
  {
    label: 'Item',
    value: 'itemName',
  },
  // {
  //   label: 'Stock',
  //   value: 'pickupPointAddress[country]',
  // },
  {
    label: 'Value',
    value: 'quantity',
  },
  {
    label: 'Unit',
    value: 'unitType',
  },
  {
    label: 'Avg Price',
    value: 'totalPrice',
  },
  // ...((isFranchise && [
  //   {
  //     action: ['view'],
  //   },
  // ]) ||
  //   []),
]

// const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

// export {roleColumns}

const roleColumns = (isFranchise = false) => {
  return columnGenerators(header(isFranchise))
}
export {roleColumns}
