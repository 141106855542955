/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  // mobile: Yup.string()
  //   .matches(/^\d*$/, 'Only numbers are allowed')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Mobile no required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  mobile: '',
  password: 'Asm@12345',
  userType: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const [lastLogin, setLastLogin] = useState<string>('CLIENT')
  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('default')) {
      try {
        const temp: any = localStorage.getItem('default')
        const decode = atob(temp)
        setIsAdmin(decode === 'man')
        setLastLogin('ADMIN')
      } catch (error) {
        console.log('error', error)
      }
    }
  }, [localStorage.getItem('default')])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        setStatus('')
        const {data: auth} = await login(values.mobile, values.password, lastLogin)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.data.userId)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const [count, setCount] = useState(0)
  const handleCount = (value) => {
    setCount(value)
    if (value === 11) {
      localStorage.setItem('default', btoa('man'))
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {(formik.status && (
        <div className='mb-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )) ||
        null}
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-white'>User Type</label>
        <select
          className='form-select form-select-solid fw-bolder'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-user-table-filter='two-step'
          data-hide-search='true'
          onChange={(e) => setLastLogin(e.target.value)}
          value={lastLogin}
        >
          ...({isAdmin && <option value='ADMIN'>ADMIN</option>}||{}),
          <option value='CLIENT'>PARTNER</option>
          {/* client/product passport/settings/ remove this one*/}
          <option value='FRANCHISE'>FRANCHISEE</option>
          {/* franchise client/franchisee/plastic credits  remove this one*/}
          <option value='AUDITOR'>AUDITOR</option>
          <option value='SPONSOR'>SPONSOR</option>
          {/* Plastic Credits-only show this one */}
        </select>
      </div>
      <div className='fv-row mb-8'>
        <label
          onClick={() => handleCount(count + 1)}
          className='form-label fs-6 fw-bolder text-white'
        >
          Login
        </label>
        <input
          placeholder='Registered mobile no without country code'
          {...formik.getFieldProps('mobile')}
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.errors.mobile},
            {
              'is-valid': !formik.errors.mobile,
            }
          )}
          type='mobile'
          name='mobile'
          autoComplete='off'
        />
        {formik.errors.mobile && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.mobile}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-white fs-6 mb-0'>Password</label>
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        <div style={{marginLeft: '370px', marginTop: '-39px'}} onClick={handleShowPassword}>
          <KTIcon iconName={showPassword ? 'eye-slash' : 'eye'} className='fs-2hx' />
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <a
          href='https://asm-recyclx.com/contact.html'
          target='_blank'
          className='link-primary text-white'
          rel='noreferrer'
        >
          Forgot Password ?
        </a>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          style={{backgroundColor: '#40C057', color: 'black'}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='text-center mb-11'>
        <h1 className='text-white fw-bolder mb-3'>Download the app</h1>
      </div>
      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            target='_blank'
            href='https://apps.apple.com/in/app/inclusiv/id6654910656'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-danger bg-state-success flex-center text-nowrap w-100 text-white'
            rel='noreferrer'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            App Store Link
          </a>
        </div>

        <div className='col-md-6'>
          <a
            target='_blank'
            href='https://play.google.com/store/apps/details?id=com.inclusive.asmglobal&pcampaignid=web_share'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-danger bg-state-success flex-center text-nowrap w-100 text-white'
            rel='noreferrer'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-play-store.svg')}
              className='h-15px me-3'
            />
            Play Store Link
          </a>
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
