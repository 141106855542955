import {FC, useMemo} from 'react'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useMutation, useQueryClient} from 'react-query'
import {deleteUser, updateUser, createUser} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {successToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {useLocation} from 'react-router-dom'

const UserConfirmationDelete = ({id, data, close}: any) => {
  console.log({data})
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const {pathname} = useLocation()
  console.log({pathname, data})
  const isWeightScale = pathname.includes('weight-scale')
  const isAuditor = pathname.includes('auditor')
  const isCertifications = pathname.includes('certifications')
  const isProductTesting = pathname.includes('product-testing')
  const values = data
  const payload = pathname.includes('weight-scale')
    ? {
        // id: values.id,
        make: values.make,
        model: values.model,
        productSerialNo: values.productSerialNo,
        capacity: values.capacity,
        yearOfPurchase: values.yearOfPurchase,
        scannedImage: values.proofEstablishment,
        calibratedOn: new Date(values.calibratedOn).getTime(),
        nextDue: new Date(values.nextDue).getTime(),
        calibrationCertificate: 'data:application/pdf;base64,JVBERi0xLjQKJeLjz9M...',
        status: 'INACTIVE',
      }
    : pathname.includes('/apps/waste-source/waste-collector')
    ? {
        identification: values.identification || 'MOBILE_NO',
        firstName: values?.personalDetails?.name,
        mobile: values?.personalDetails?.mobile,
        middleName: '',
        lastName: '',
        userType: 'CUSTOMER',
        customerType: values.customerType,
        // customerType: 'CORPORATE',
        // address: values.address,
        confirm: values.confirm || true,
        kycDocument: [{docUrl: values.bussinessImage, docType: 'POI'}],
        address: {
          city: '',
          country: '',
          latitude: 0,
          longitude: 0,
          state: null,
          street: '',
          zipCode: '',
        },
        title: values.title,
        dob: values.dob ? new Date(values?.dob).toISOString().split('T')[0] : '',
        personalDetails: {
          name: values.name,
          email: null,
          mobile: null,
          profileImage: null,
        },
        status: 'INACTIVE',
      }
    : {
        firstName: values?.personalDetails?.name,
        mobile: values?.personalDetails?.mobile,
        userType: isCertifications ? '' : isAuditor ? 'AUDITOR' : 'PICKUP_POINT_STAFF',
        // customerType: 'CORPORATE',
        email: values?.personalDetails?.email,
        status: 'INACTIVE',
        lastName: '',
        password: '',
        address: {
          country: values?.address?.countryCode || '',
        },
      }
  const deleteItem = useMutation(
    () =>
      updateUser(
        payload,
        isCertifications
          ? `certification/edit/${data.id}`
          : isProductTesting
          ? `items/${data?.tests?.[0]?.testId}/update`
          : isWeightScale
          ? `weightscales/${data.id}/update`
          : `users/${data.id}/update`
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        successToast(`Deleted`)
        close()
      },
    }
  )
  return (
    <>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div
          className={`modal-dialog 
         mw-650px modal-dialog-centered`}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={close}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div id='kt_modal_add_user' className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div className='text-center flex justify-center'>
                Are you sure you want to delete ?
              </div>
              <div style={{textAlign: 'center'}}>
                <br />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='reset'
                onClick={() => close()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                // disabled={formik.isSubmitting || isUserLoading}
              >
                No
              </button>
              <button
                onClick={async () => {
                  await deleteItem.mutateAsync()
                }}
                type='submit'
                className='btn btn-danger'
                data-kt-users-modal-action='submit'
                style={{backgroundColor: '#333333'}}
              >
                <span className='indicator-label'>Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {UserConfirmationDelete}
