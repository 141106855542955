import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {label: 'S.N', value: 'id'},
  {
    label: 'Make',
    value: 'make',
  },
  /* {
    label: 'Birthday',
    value: 'birthday',
  },
  {
    label: 'Gender',
    value: 'gender',
  },*/
  /* {
    label: 'Email Address',
    value: 'email',
  }, */
  {
    label: 'Model',
    value: 'model',
  },
  {
    label: 'Serial No.',
    value: 'productSerialNo',
  },
  {
    label: 'Capacity',
    value: 'capacity',
  },
  {
    label: 'Year Of Purchase',
    value: 'yearOfPurchase',
  },
  {
    label: 'Calibrated on.',
    value: 'calibratedOn',
  },
  {
    label: 'Next Due.',
    value: 'nextDue',
  },
  {
    action: ['edit', 'qr', 'delete'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
