import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Name of Standard',
    value: 'auditName',
  },
  {
    label: 'Issuing Authority',
    value: 'auditAuthorities',
  },
  {
    label: 'Version No',
    value: 'versionNo',
  },
  {
    label: 'Issued On',
    value: 'issueOn',
  },
  {
    label: 'Logo On',
    value: 'logo',
  },
  {
    action: ['edit'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
