import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {errorToast} from '../../../../../../_metronic/helpers/components/Toaster'
import {object} from 'yup'

const API_URL = process.env.REACT_APP_BASE_API_URL
const USER_URL = `${API_URL}`
const GET_USERS_URL = `${API_URL}`

const getUsers = (query: string, initialApi: string = 'users'): Promise<UsersQueryResponse> => {
  const base = API_URL + initialApi
  const baseUrl = `${base}${base.includes('?') ? '&' : '?'}${query}`

  return axios.get(baseUrl).then((d: any) => {
    const value = d?.data?.data ? d.data : d
    return baseUrl.includes('yearlyReport')
      ? {data: value?.data?.monthlyDetails || []}
      : baseUrl.includes('api/pickupPoints/stock')
      ? {data: value?.data?.inventoryDetails}
      : baseUrl.includes('user/due')
      ? {data: value?.data?.transactions, ...value.data}
      : d?.data?.data
      ? d.data
      : d
  })
}

const getUserById = (id?: any, api: any = ''): Promise<User | undefined> => {
  return axios
    .get(`${GET_USERS_URL + api || USER_URL}${id || ''}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data || response)
}

const createUser = (user: any, url?: any): Promise<User | undefined> => {
  console.log({user, url})
  const baseURL = url?.includes('http') ? url : url ? USER_URL + url : USER_URL
  return axios
    .post(baseURL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: any, url?: any): Promise<User | undefined> => {
  const baseURL = url?.includes('http') ? url : url ? USER_URL + url : USER_URL
  return axios
    .put(baseURL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
  // .catch((error) => {
  //   if (error.response && error.response.status === 500) {
  //     errorToast(error.message)
  //   } else {
  //     errorToast(error.message)
  //   }
  //   return undefined
  // })
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

// const updatedSelectedUsers = (selectUserId: Array<ID>): Promise<User | undefined> => {
//   const baseURL = USER_URL + 'productions/assignAuditor'
//   return axios
//     .put(baseURL, selectUserId)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }
const updatedSelectedUsers = (selectUserId: Array<ID>): Promise<User | undefined> => {
  const baseURL = USER_URL + 'productions/assignAuditor'
  return axios
    .put(baseURL, selectUserId)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
    .catch((error) => {
      if (error.response && error.response.status === 500) {
        errorToast(error.message)
      } else {
        errorToast(error.message)
      }
      return undefined
    })
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  updatedSelectedUsers,
  getUserById,
  createUser,
  updateUser,
}
