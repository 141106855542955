import React from 'react'
import QRCode from 'qrcode.react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import html2canvas from 'html2canvas'
import {useLocation} from 'react-router-dom'

import {LuBuilding} from 'react-icons/lu'
import {IoDocumentTextOutline} from 'react-icons/io5'
import {HiOutlinePuzzle} from 'react-icons/hi'
import {LuEarth} from 'react-icons/lu'
import {GoDatabase} from 'react-icons/go'
import {FaDiceD20} from 'react-icons/fa'
import {FiTruck} from 'react-icons/fi'
import {FaDotCircle} from 'react-icons/fa'
import {GoDotFill} from 'react-icons/go'
import {CiLocationOn} from 'react-icons/ci'
import {GiWeight} from 'react-icons/gi'
import {CiCalendarDate} from 'react-icons/ci'
import {RiBuilding2Line} from 'react-icons/ri'
import {FaCalendarAlt} from 'react-icons/fa'
import {FaLocationDot} from 'react-icons/fa6'

const SmartQuestioningItem = ({icon, text}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4px',
    }}
  >
    <div
      style={{
        marginRight: '8px',
        fontSize: '20px',
        color: '#4A5568',
      }}
    >
      {icon}
    </div>
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '5px 8px',
        backgroundColor: '#eeeff1',
        borderRadius: '10px',
        fontSize: '14px',
        color: '#4A5568',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
      }}
    >
      <span>{text}</span>
    </div>
  </div>
)

const GenerateQrCode = ({data, close, action}) => {
  console.log('data is', data)

  const isProductDetails = action.includes('product-details')
  const {pathname} = useLocation()

  const staticData = {
    id: data?.id || data,
    name: data.id && data?.personalDetails?.name,
    mobile: data?.personalDetails?.mobile || '',
    userType: 'CUSTOMER',
  }

  const downloadQRCode = () => {
    const element = document.getElementById('kt_modal_add_user')
    console.log({element})

    if (element) {
      html2canvas(element, {
        // scale: 2, // Adjust the scale as needed
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')

        // Create a link element to download the PNG
        const link = document.createElement('a')
        link.href = imgData
        // link.download = 'qrcode.png'
        link.download = `${data.productionId}.png`
        link.click()
      })
    }
  }

  const isWasteSource = pathname.includes('waste-source')

  return (
    <>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog mw-750px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{isProductDetails ? 'Product' : 'QR CODE'}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={close}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div
              id='kt_modal_add_user'
              style={{
                backgroundColor: 'white',
                padding: '20px',
                // border: "2px solid red",
                paddingLeft: '50px',
              }}
            >
              <div style={{marginBottom: '20px'}}>
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '24px',
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#4A5568',
                      letterSpacing: '0.5px',
                    }}
                  >
                    <FaDotCircle
                      style={{color: '#2061c6', fontSize: '10px', display: 'inline-block'}}
                    />{' '}
                    POLYMER
                  </span>

                  <h1
                    style={{
                      fontSize: '20px',
                      fontWeight: '600',
                      color: '#2D3748',
                      marginBottom: '8px',
                      lineHeight: '1.2',
                    }}
                  >
                    {data?.productionItemDetails?.[0]?.itemName}
                  </h1>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#718096',
                      margin: 0,
                    }}
                  >
                    Product ID: {data.productionId}
                  </p>
                </div>
                <img
                  src='/api/placeholder/80/80'
                  alt='Logo'
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'contain',
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '14px',
                  marginBottom: '32px',
                }}
              >
                {/* First Item */}
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <LuBuilding style={{fontSize: '20px', color: '#777f8e'}} />
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#4A5568',
                      padding: '5px 8px',
                      backgroundColor: '#eeeff1',
                      borderRadius: '10px',
                    }}
                  >
                    {data.franchiseName}
                  </span>
                </div>

                {/* Second Item */}
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <GiWeight style={{fontSize: '18px', color: '#777f8e'}} />
                  <span
                    style={{
                      padding: '5px 8px',
                      backgroundColor: '#eeeff1',
                      borderRadius: '10px',
                      fontSize: '14px',
                      color: '#4A5568',
                      cursor: 'pointer',
                    }}
                  >
                    {data?.productionItemDetails?.[0]?.quantity} kg
                  </span>
                </div>

                {/* Third Item */}
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <FaDiceD20 style={{fontSize: '18px', color: '#777f8e'}} />
                  <span
                    style={{
                      padding: '5px 8px',
                      backgroundColor: '#eeeff1',
                      borderRadius: '10px',
                      fontSize: '14px',
                      color: '#4A5568',
                      cursor: 'pointer',
                    }}
                  >
                    Batch traceability
                  </span>
                </div>
              </div>

              <div style={{marginBottom: '32px'}}>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#2D3748',
                    marginBottom: '16px',
                  }}
                >
                  <span style={{color: '#4C6EF5', fontWeight: 'bold'}}>
                    Manufacturing Information
                  </span>
                </div>

                <div style={{marginBottom: '15px'}}>
                  <SmartQuestioningItem
                    icon={<FaLocationDot />}
                    text={data?.pickupPointInfo?.address?.country}
                  />
                </div>

                <div style={{marginBottom: '15px'}}>
                  <SmartQuestioningItem
                    icon={<FaCalendarAlt />}
                    text={new Date(+data?.createdAt || new Date()).toLocaleDateString()}
                  />
                </div>

                <div style={{marginBottom: '15px'}}>
                  <SmartQuestioningItem icon={<RiBuilding2Line />} text={data?.processName} />
                </div>
              </div>

              <div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#2D3748',
                    marginBottom: '16px',
                  }}
                >
                  Chain of custody
                </div>

                <div
                  style={{
                    borderLeft: '2px solid #E2E8F0',
                    paddingLeft: '20px',
                    marginLeft: '24px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      left: '-30px',
                      top: '-5px',
                    }}
                  >
                    <FiTruck style={{color: '#777f8e'}} />
                  </div>

                  <div
                    style={{
                      position: 'relative',
                      marginBottom: '24px',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        left: '-25px',
                        top: '0',
                        width: '8px',
                        height: '8px',
                        backgroundColor: '#E2E8F0',
                        borderRadius: '50%',
                      }}
                    />
                    <div
                      style={{
                        fontSize: '13px',
                        color: '#718096',
                        marginBottom: '4px',
                      }}
                    >
                      07/09/2022, 15:46
                    </div>

                    <div
                      style={{
                        fontSize: '14px',
                        color: '#2D3748',
                        fontWeight: 'bold',
                      }}
                    >
                      Polymer sent [-2475 kg]{' '}
                      <span style={{color: '#4C6EF5'}}>
                        {' '}
                        <GoDotFill
                          style={{
                            color: '#215cbc',
                            fontSize: '15px',
                            marginBottom: '-2px',
                            display: 'inline-block',
                          }}
                        />{' '}
                        SAMSONITE
                      </span>
                    </div>
                  </div>

                  <div style={{position: 'relative'}}>
                    <div
                      style={{
                        position: 'absolute',
                        left: '-25px',
                        top: '0',
                        width: '8px',
                        height: '8px',
                        backgroundColor: '#E2E8F0',
                        borderRadius: '50%',
                      }}
                    />
                    <div
                      style={{
                        fontSize: '13px',
                        color: '#718096',
                        marginBottom: '4px',
                      }}
                    >
                      22/08/2022, 11:31
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#2D3748',
                        fontWeight: 'bold',
                      }}
                    >
                      Polymer manufactured from{' '}
                      <span
                        style={{
                          padding: '5px 8px',
                          backgroundColor: '#eeeff1',
                          borderRadius: '10px',
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                      >
                        {' '}
                        <FaDotCircle
                          style={{color: 'red', fontSize: '10px', display: 'inline-block'}}
                        />
                        2433A...85A2D
                      </span>{' '}
                      [+2500 kg]
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: '32px',
                  // backgroundColor: '#eeeff1',
                  padding: '16px',
                  borderRadius: '4px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    width: '64px',
                    height: '64px',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '48px',
                      height: '48px',
                    }}
                  >
                    <QRCode
                      value={JSON.stringify(staticData)}
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#718096',
                    }}
                  >
                    Scan for a
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#4332b2',
                      fontWeight: 'bold',
                    }}
                  >
                    Digital Product Passport
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: '24px',
                  textAlign: 'right',
                  fontSize: '12px',
                  color: '#A0AEC0',
                }}
              />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                onClick={close}
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                style={{backgroundColor: '#12B886'}}
                type='button'
                onClick={() => {
                  downloadQRCode()
                  close()
                }}
                className='btn btn-primary'
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default GenerateQrCode
