/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Button} from 'react-bootstrap'
import {UsersListToolbar} from './UserListToolbar'
import {useLocation} from 'react-router-dom'
import ExcelJS from 'exceljs'
import {header as headerCollect} from '../../collect-orders/_columns'
import {header as headerProcessing} from '../../processing-record/_columns'
import {header as headerAnualreport} from '../../invenotry/_columns'
import {header as headerStock} from '../../stock/_columns'
import {header} from '../../return-orders/_columns'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {HelloTesting} from '../../../../../../../_metronic/helpers/returnDataHelper'

const UsersListSearchComponent = ({
  placeholder = 'Search user',
  searchElements = [],
  showResetButton = false,
  initialApi = '',
  showDownload = false,
  showPayNow,
}) => {
  const {updateState} = useQueryRequest()
  const {response}: any = useQueryResponse()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [searchQueryData, setSearchQueryData] = useState<any>({})

  // const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
  //       updateState({search: debouncedSearchTerm, ...initialQueryState})
  //     }
  //   },
  //   [debouncedSearchTerm]
  // )

  const handleChange = (name: string, value: string) => {
    setSearchQueryData({...searchQueryData, [name]: value})
  }
  const handleResetQuery = () => {
    setSearchQueryData({})
    updateState({
      ...initialQueryState,
    })
  }

  const handleSearchFilter = () => {
    console.log(initialQueryState, searchQueryData)
    updateState({
      ...((pathname.includes('/apps/reports/inventory') && {
        ...initialQueryState,
        ...searchQueryData,
        initialApi: `yearlyReport${
          searchQueryData.franchiseeid ? '/' + searchQueryData.franchiseeid : ''
        }/${searchQueryData.status}?year=${searchQueryData?.start_date || 2025}`,
        enabled: true,
      }) || {
        ...initialQueryState,
        ...searchQueryData,
      }),
    })
  }
  const renderSearch = () => {
    return searchElements.map((eachSearch: any, eachIndex: number) => {
      switch (eachSearch.type) {
        case 'innn':
          return (
            <div key={eachIndex + 1 + ''}>
              <label className='fw-bold fs-6 mb-2'>{eachSearch.label}</label>
              <input
                readOnly
                // placeholder={placeholder}
                type='text'
                name={'name'}
                value={eachSearch.value}
                className='form-control form-control-solid mb-3 mb-lg-0'
                /*  className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched[name] && formik.errors[name]},
                  {
                    'is-valid': formik.touched[name] && !formik.errors[name],
                  }
                )} */
                autoComplete='off'
                // disabled={formik.isSubmitting || isUserLoading}
              />
              {/*  {formik.touched[name] && formik.errors[name] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors[name]}</span>
                  </div>
                </div>
              )} */}
            </div>
          )
        case 'datePicker':
          return (
            <div key={eachIndex + 1 + ''}>
              <label className='form-label fs-6 fw-bold'>{eachSearch.label || ''}</label>
              <br />
              <DatePicker
                placeholderText={`Select ${eachSearch.label}`}
                className='form-control form-control-solid w-250px ps-14'
                selected={searchQueryData[eachSearch.name] || null}
                {...((pathname.includes('apps/reports/inventory') && {
                  showYearDropdown: true,
                  showMonthYearPicker: false,
                  showFourColumnMonthYearPicker: true,
                  prevMonthButtonDisabled: true,
                  nextMonthButtonDisabled: true,
                  maxDate: new Date(),
                  // showFullMonthYearPicker: true,
                }) ||
                  [])}
                {...((pathname.includes('apps/monthly-volume') && {
                  showYearDropdown: true,
                  showMonthYearPicker: true,
                  showFourColumnMonthYearPicker: true,
                  // showFullMonthYearPicker: true,
                }) ||
                  [])}
                {...((pathname.includes('apps/monthly-reports') && {
                  showMonthYearPicker: true,
                  showFullMonthYearPicker: true,
                  showFourColumnMonthYearPicker: true,
                }) ||
                  [])}
                onChange={(date) =>
                  handleChange(eachSearch.name, new Date(date).toLocaleDateString())
                }
              />{' '}
            </div>
          )
        case 'select':
          return (
            <div key={eachIndex + 1 + ''} className=''>
              <label className='form-label fs-6 fw-bold'>{eachSearch.label || ''}</label>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                onChange={(e) =>
                  showResetButton
                    ? handleChange(eachSearch.name, e.target.value)
                    : /* 
                    curl --location 'http://localhost:8081/v1/api/yearlyReport/66d61cda9976df14285c8f1f/DISPATCH?year=2024' \
                    */
                      updateState({
                        [pathname.includes('mass-balance') ? 'pickupPointId' : 'type']:
                          e.target.value,
                        initialApi: pathname.includes('/apps/reports/inventory')
                          ? `yearlyReport/${searchQueryData.franchiseeid}/DISPATCH?year=${e.target.value}`
                          : pathname.includes('/apps/transactions/purchase-orders')
                          ? `purchaseOrders?type=SOURCE&pickupPointId=${e.target.value}`
                          : pathname.includes('/apps/product-passport')
                          ? `productions?processType=DIVERSION&pickupPoint=${e.target.value}`
                          : pathname.includes('stock')
                          ? `pickupPoints/stock/${e.target.value}`
                          : pathname.includes('mass-balance')
                          ? `stock`
                          : pathname.includes('payments')
                          ? `user/due/${e.target.value}`
                          : `categories/${e.target.value}/${eachSearch?.queryType || 'items'}`,
                        ...initialQueryState,
                      })
                }
                value={
                  showResetButton
                    ? searchQueryData[eachSearch.name]
                    : searchQueryData[eachSearch.name]
                }
              >
                {eachSearch.options.map((eachOption, eachInd) => (
                  <option key={eachInd + 1 + ''} value={eachOption.value}>
                    {eachOption.label}
                  </option>
                ))}
              </select>
            </div>
          )
        default:
          break
      }
    })
  }
  const {pathname} = useLocation()

  const exportToExcel = async (data, fileName) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet1')
    worksheet.columns = changeHeader
    worksheet.getColumn(2).width = 20
    for (let index = 0; index < data?.length; index++) {
      const item = data[index]
      // worksheet.addRow({...item, categoryIcon: ''})
      worksheet.addRow({...item})
      /*  if (item.categoryIcon) {
        const base64: any = await fetchImageAsBase64(item.categoryIcon)
        const imageId = workbook.addImage({base64, extension: 'png'})
        worksheet.addImage(imageId, {
          tl: {col: 5, row: index + 1 + 0.99},
          ext: {width: 50, height: 50},
        })
      } */
    }

    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) row.height = 60 // Adjust height to fit image
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `${fileName}.xlsx` // Add .xlsx extension
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const isCollectOrder = pathname.includes('collect-orders')
  const isProcessingRecord = pathname.includes('processing-record')
  const isAnnualReport = pathname.includes('inventory')
  const isStockReport = pathname.includes('stock')
  // Simplified header processing
  const changeHeader = (
    isStockReport
      ? headerStock()
      : isAnnualReport
      ? headerAnualreport
      : isCollectOrder
      ? headerCollect
      : isProcessingRecord
      ? headerProcessing
      : header
  )
    .slice(
      0,
      isAnnualReport || isStockReport ? undefined : isCollectOrder || isStockReport ? -1 : -2
    )
    .map((x) => ({
      header: x.label?.toUpperCase(),
      key: x.value,
      width: x.label === 'S.N' ? 5 : 25,
    }))
  // Simplified data rendering
  const dataToRender: any = response?.data?.length
    ? response?.data?.map((each, index) => ({
        'S.N': index + 1 + '',
        id: index + 1 + '',
        ...changeHeader
          .filter(({header}) => header !== 'S.N')
          .reduce((acc, {key}: any) => {
            const mappings = {
              // createdAt: '',
              status: each[key],
              quantity: each.quantity,
              mapLocation:
                each?.centreInfo?.address?.latitute + ' , ' + each?.centreInfo?.address?.longitute,
              // categoryName: each?.orderDetails?.[0]?.categoryName || each?.categoryName || '',
              categoryName:
                (each?.orderDetails || each?.orderDetail)?.[0]?.categoryName ||
                each?.categoryName ||
                '',
              categoryIcon: each?.orderDetails?.[0]?.categoryIcon || '',
              qualityRating: each.qualityRating + '',
              paymentMode: each.paymentMode || '',
              processType: each.processType || '',
              processName: each.processName || '',
              totalPrice: each.totalPrice || '0',
              quantityDeposit: each?.orderDetails?.[0]?.items
                ?.reduce((acc, curr) => acc + curr.quantity, 0)
                .toString(),
              pickup_quantity: each?.orderDetails?.[0]?.items.map((x) => x.quantity).join(','),
              pickup_quantity_item: each?.orderDetails?.[0]?.items
                ?.map((y) => y.itemName)
                .join(','),
            }
            acc[key] =
              mappings[key] !== undefined ? mappings[key] : HelloTesting({user: each, mapData: key})
            return acc
          }, {}),
      }))
    : []
  const handleExport = () => {
    exportToExcel(
      dataToRender,
      isStockReport
        ? 'stock'
        : isAnnualReport
        ? 'annual-report'
        : isCollectOrder
        ? 'collect-orders'
        : isProcessingRecord
        ? 'processing-record'
        : 'supply-orders'
    )
  }

  return (
    <>
      <div
        className={`grid grid-cols-${pathname.includes('payments') ? 5 : 4} gap-3 ${
          pathname.includes('mass-balance') ? '-mt-4' : 'my-2'
        }`}
      >
        {(searchElements.length > 0 && (
          <>
            {renderSearch()}
            {showPayNow && (
              <div
                className='flex'
                style={{
                  marginTop: '27px',
                }}
              >
                <button
                  type='button'
                  // onClick={handleResetQuery}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='reset'
                  disabled={Object.keys(searchQueryData).length === 0}
                >
                  Pay Now
                </button>
              </div>
            )}
            <div
              className='flex'
              style={{
                marginTop: '27px',
              }}
            >
              {showResetButton && (
                <>
                  <button
                    type='button'
                    onClick={handleResetQuery}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    disabled={Object.keys(searchQueryData).length === 0}
                  >
                    Reset
                  </button>
                  <button
                    type='button'
                    style={{backgroundColor: '#12B886'}}
                    onClick={handleSearchFilter}
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                    disabled={!(Object.keys(searchQueryData).length > 0)}
                  >
                    Apply
                  </button>
                </>
              )}
              &nbsp;
              {showDownload && (
                <button
                  type='button'
                  style={{backgroundColor: '#333333'}}
                  onClick={handleExport}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                  disabled={dataToRender && dataToRender.length === 0}
                >
                  Download
                </button>
              )}
            </div>
          </>
        )) ||
          null}
      </div>
    </>
  )
}

export {UsersListSearchComponent}
