import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

export const header = [
  {
    label: 'S.N',
    value: 'S.N',
  },

  {
    label: 'Date',
    value: 'createdAt',
  },
  {
    label: 'PO Number',
    value: 'purchaseOrderId',
  },
  {
    label: 'Buyer',
    value: 'fromCentreName',
  },
  /*  {
    label: 'Franchise',
    value: '-',
  },
  {
    label: 'Address',
    value: 'pickup_address',
  }, */
  {
    label: 'Category',
    value: 'categoryName',
  },
  /* {
    label: 'Icon',
    value: 'categoryIcon',
  }, */
  {
    label: 'Item',
    value: 'pickup_quantity_item',
  },
  {
    label: 'Quantity',
    value: 'pickup_quantity',
  },
  {
    label: 'Seller',
    value: 'toCentreName',
  },
  /*  {
    label: 'Delivered On',
    value: 'completedAt',
  }, */
  {
    label: 'Status',
    value: 'status',
  },

  // {
  //   label: 'certificate_view',
  // },
  {
    action: ['qr'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
