/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ChartsWidget1,
  ChartsWidget2,
  ListsWidget4,
  ListsWidget2,
  ListsWidget6,
  StatisticsWidget5,
  StatisticsWidget6,
  TablesWidget10,
  TablesWidget13,
  TablesWidget9,
} from '../../../_metronic/partials/widgets'
import MapComponent from '../../modules/apps/data-administration/data-admininstration-list/Map'
import {useQuery} from 'react-query'
import {getUserById} from '../../modules/apps/data-administration/data-admininstration-list/core/_requests'
import {useAuth} from '../../modules/auth'
import {UsersListHeader} from '../../modules/apps/data-administration/data-admininstration-list/components/header/UsersListHeader'
import {useFetchCommon} from '../../../_metronic/helpers/crud-helper/useQuery'

const DashboardPage: FC = () => {
  const {auth, isSponsor} = useAuth()

  const {responseData: impact} = useFetchCommon({
    api: `impact`,
    isEnabled: true,
    isDropDown: false,
  })
  const {responseData: newMapData} = useFetchCommon({
    api: `users?page=0&size=10&type=WASTE_SOURCE`,
    isEnabled: true,
    isDropDown: false,
  })
  const {data = {}} = useQuery(`admin/dashboard`, () => getUserById('', 'admin/dashboard'), {
    cacheTime: 0,
    onError: (err) => {
      console.warn(err)
    },
  })
  const {data: analyticsData = {}} = useQuery(
    `admin/pickupPoints/analytics`,
    () => getUserById('', 'admin/pickupPoints/analytics'),
    {
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )

  const {data: cleanUpData = {}} = useQuery(
    `collect/orders?page=1&size=10`,
    () => getUserById('', 'collect/orders?page=1&size=10'),
    {
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )

  const {data: dispatchData = {}} = useQuery(
    `convert/orders?page=1&size=10`,
    () => getUserById('', 'convert/orders?page=1&size=10'),
    {
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )
  const {data: franchiseData} = useQuery(
    `users?page=0&size=10&type=FRANCHISE`,
    () => getUserById('', 'users?page=0&size=10&type=FRANCHISE'),
    {
      // enabled: isSponsor,
      enabled: false,
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )
  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  useEffect(() => {
    if (data && data.length) {
      let dropdownData: any = [{label: 'Select One', value: ''}]
      data.map((eachData) => {
        return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
      })
      setCategoriesList(dropdownData)
      // updateState({
      //   enabled: true,
      //   initialApi: `categories/${dropdownData[0].value}/items`,
      //   ...initialQueryState,
      // })
    }
  }, [data])

  const [leaderBoardXaxisData, setleaderBoardXaxisData] = useState([])
  const [leaderBoardYaxisData, setleaderBoardYaxisData] = useState([])

  useEffect(() => {
    if (analyticsData.length) {
      const transformedData: any = []

      const properties = Object.keys(analyticsData[0]).filter(
        (key) =>
          key !== 'pickupPointId' &&
          key !== 'pickupPointName' &&
          key !== 'pickupPointAddress' &&
          key !== 'percentageCapacityDiverted' &&
          key !== 'percentageAudited'
      )
      properties.forEach((property) => {
        const dataValues = analyticsData?.map((obj) =>
          Array.isArray(obj[property]) ? '' : parseFloat(obj[property]?.toFixed(2))
        )
        transformedData.push({
          name: property,
          data: dataValues,
        })
      })
      const collectionPointName = analyticsData.map((obj) => obj.pickupPointName)
      setleaderBoardXaxisData(collectionPointName)
      setleaderBoardYaxisData(transformedData)
    }
  }, [analyticsData])

  const [mapLocation, setMapLocation] = useState<any>([])
  const [newMapLocation, setNewMapLocation] = useState<any>([])
  const [newDataMapLocation, setDatanewMapLocation] = useState<any>([])

  const {pickupPoints, collectTrend, productionTrend, suppliedTrend} = data
  useEffect(() => {
    if (pickupPoints?.length) {
      const modifyData = pickupPoints.map((x) => {
        return {
          position: {
            lat: x.latitude,
            lng: x.longitude,
          },
          name: x.name,
        }
      })
      // console.log({modifyData})
      setMapLocation(modifyData)
    }
  }, [pickupPoints])

  useEffect(() => {
    if (newMapData?.length) {
      const newMapModify = newMapData.map((x) => {
        return {
          position: {
            lat: x?.address?.latitute,
            lng: x?.address?.longitute,
          },
          name: x?.address?.city,
        }
      })
      setNewMapLocation(newMapModify)
    }
  }, [newMapData])

  useEffect(() => {
    if (mapLocation.length && newMapLocation.length) {
      setDatanewMapLocation([...mapLocation, ...newMapLocation])
    }
  }, [mapLocation, newMapLocation])

  const mapDate = [
    `${new Date().getFullYear()}-01`,
    `${new Date().getFullYear()}-02`,
    `${new Date().getFullYear()}-03`,
    `${new Date().getFullYear()}-04`,
    `${new Date().getFullYear()}-05`,
    `${new Date().getFullYear()}-06`,
    `${new Date().getFullYear()}-07`,
    `${new Date().getFullYear()}-08`,
    `${new Date().getFullYear()}-09`,
    `${new Date().getFullYear()}-10`,
    `${new Date().getFullYear()}-11`,
    `${new Date().getFullYear()}-12`,
  ]
  const [collectedGraphData, setCollectedGraphData] = useState<any>({})
  const [suppliedGraphData, setSuppliedGraphData] = useState<any>({})
  const [processGraphData, setProcessGraphData] = useState<any>({})

  const updateGraphData = (trendData, setGraphData, graphData) => {
    if (trendData && Object.keys(trendData)?.length > 0) {
      const test = mapDate.map(
        (x) => (trendData[x] && (trendData?.[x] / 1000)?.toFixed(2)) || '0.00'
      )
      setGraphData({...graphData, monthValue: test})
    }
  }

  const updateGraphData2 = (trendData, setGraphData, graphData) => {
    if (trendData && Object.keys(trendData)?.length > 0) {
      const label = Object.keys(trendData).map((date) => {
        const [year, month, day] = date.split('-') // Split the date string
        return `${month}-${day}` // Format as "day-month"
      })
      // const data = Object.values(trendData)
      const data = Object.values(trendData)?.map((value: any) => parseFloat(value).toFixed(2))
      setGraphData({...graphData, label, data})
    }
  }

  useEffect(() => {
    updateGraphData2(collectTrend, setCollectedGraphData, collectedGraphData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectTrend])

  useEffect(() => {
    updateGraphData2(productionTrend, setProcessGraphData, processGraphData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionTrend])

  useEffect(() => {
    updateGraphData2(suppliedTrend, setSuppliedGraphData, suppliedGraphData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliedTrend])

  const numberItems = [
    {value: 'collected', name: 'Collected from waste pickers', icon: 'Collected', color: '#ffffff'},
    {
      value: 'received',
      name: 'Material transfer within supply chain',
      icon: 'Production',
      color: '#ffffff',
    },
    {
      value: 'externalSale',
      name: 'Material Sold to End Users',
      icon: 'Dispatched',
      color: '#ffffff',
    },
    {value: 'stock', name: 'Stock', icon: 'new-stock', color: '#ffffff'},
    {
      value: 'collectionPoints',
      name: 'Supply Chain Facilities',
      icon: 'Customer',
      color: '#ffffff',
    },
    {
      value: 'diversionProductionQuantity',
      name: 'Material Recycled',
      icon: 'waste diverter',
      color: '#ffffff',
    },
    {value: 'wastage', name: 'Process Loss', icon: 'process loss', color: '#ffffff'},
    {
      value: 'processed',
      name: 'Material Processed',
      icon: 'emission',
      color: '#ffffff',
    },
  ]

  /* const numberItems = [
  {name: 'Collected', icon: 'Collected', color: '#ffffff', value: 'collected'},
  {name: 'Processed', icon: 'Production', color: '#ffffff', value: 'processed'},
  {name: 'Dispatched', icon: 'Dispatched', color: '#ffffff', value: 'dispatched'},
  {name: 'Stock', icon: 'Stock', color: '#ffffff', value: 'mrfWaste'},
  {name: 'Customers', icon: 'Customer', color: '#ffffff', value: 'mrfWaste'},
  {name: 'Residuals', icon: 'Residuaks', color: '#ffffff', value: 'recyclingRatio'},
  {name: 'Staff', icon: 'Staff', color: '#ffffff', value: 'resourceRecovery'},
  {name: 'C02 Avoided', icon: 'emission', color: '#ffffff', value: 'coprocessingRatio'},
] */
  /* const searchElements = [
    {
      ...(isSponsor && {
        type: 'select',
        value: 'select',
        options: categoriesList,
        name: 'franchise',
        label: 'Franchise',
      }),
    },
  ] */
  return (
    <>
      {/*   <UsersListHeader
        searchElements={searchElements}
        placeholder='Search Category'
        initialApi='convert/orders'
      /> */}
      <div className='row g-xl-4' style={{marginBottom: '20px'}}>
        {numberItems.map((eachitems, eachIndex) => (
          <div key={eachIndex + 1 + ''} className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              // svgIcon={`/media/svg/dashboard/co2.png`}
              img={eachitems.icon}
              color={eachitems.color || 'warning'}
              iconColor='primary'
              // title={eachitems.value}
              title={`
             ${
               data[eachitems.value]?.toFixed(
                 [
                   'collectionPoints',
                   'diversionFacilities',
                   'wasteDiverters',
                   'lifeImpacted',
                   //  'wastage',
                 ].includes(eachitems.value)
                   ? 0
                   : 2
               ) || ''
             }
             ${
               [
                 'collectionPoints',
                 'diversionFacilities',
                 'wasteDiverters',
                 'lifeImpacted',
                 'wastage',
               ].includes(eachitems.value)
                 ? ''
                 : ' kg'
             }
           `}
              // titleColor='primary'
              description={eachitems.name}
              // descriptionColor='primary'
            />
          </div>
        ))}
        {/* {numberItems.map((eachitems, eachIndex) => (
          <div key={eachIndex + 1 + ''} className='bgi-no-repeat col col-lg-3'>
            <div
              className='d-flex align-items-center rounded p-7 mb-1'
              style={{
                backgroundColor: eachitems.color,
              }}
            >
              <span className=' text-success me-5'>
                <img
                  src={`/media/svg/dashboard/${eachitems.icon}.png`}
                  alt=''
                  width={50}
                  height={50}
                />
              </span>
              <div className='flex-grow-1 me-2 text-white'>
                {eachitems.name}
                <span style={{fontSize: '22px'}} className='text-white fw-semibold d-block'>
                  {data[eachitems.value]?.toFixed(
                    ['collectionPoints', 'wasteDiverters', 'lifeImpacted'].includes(eachitems.value)
                      ? 0
                      : 2
                  ) || ''}{' '}
                  {['collectionPoints', 'wasteDiverters', 'lifeImpacted'].includes(eachitems.value)
                    ? ''
                    : ' kg'}
                </span>
              </div>
            </div>
          </div>
        ))} */}
      </div>
      {(newDataMapLocation?.length && (
        <div>
          <MapComponent data={newDataMapLocation || []} />
        </div>
      )) ||
        null}
      <div className='row g-3 g-xl-8 mt-2'>
        <div className='col-xl-3'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='success'
            title=''
            description='Diversion Capacity (Metric Ton)'
            progress={data?.diversionCapacities || ''}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='warning'
            title=''
            description='End of Waste Processors'
            progress={data?.diversionFacilities || ''}
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title=''
            description='Waste Supplier'
            progress={data?.lifeImpacted || ''}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title=''
            description='Co2 footprint avoided (Kg)'
            progress={impact?.totalSavings || ''}
          />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 data={data?.topCustomers || {}} className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 data={data?.pickupPoints || []} className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4
            data={data?.topEndCustomers || {}}
            className='card-xl-stretch mb-xl-8'
            items={5}
          />
        </div>
      </div>

      <TablesWidget10 data={cleanUpData} className='mb-5 mb-xl-8' />

      <br />
      <TablesWidget13 data={dispatchData} className='mb-5 mb-xl-8' />

      <div className='row g-2 g-xl-8'>
        {/*  <div>
          <ChartsWidget2
            className='card-xl-stretch mb-xl-8 mt-8'
            x_axis={leaderBoardXaxisData}
            y_axis={leaderBoardYaxisData}
          />
        </div> */}
        <div className=''>
          <ChartsWidget1
            label={collectedGraphData.label}
            data={collectedGraphData?.data}
            title='Material Collected (Kgs)'
            className='card-xl-stretch mb-xl-8'
            // subTitle='Highest daily avg Fisherman collector'
          />
        </div>
        <div className=''>
          <ChartsWidget1
            label={processGraphData.label}
            data={processGraphData?.data}
            title='Material Processed (Kgs)'
            className='card-xl-stretch mb-xl-8'
            // subTitle='Highest daily avg community collector'
          />
        </div>
      </div>
      <div className=''>
        <ChartsWidget1
          label={suppliedGraphData.label}
          data={suppliedGraphData?.data}
          title='Material Supplied (Kgs)'
          className='card-xl-stretch mb-xl-8'
          // subTitle='Highest daily avg community collector'
        />
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
