/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth, isAdmin, isAuditor, isFranchise, isPartner, isSponsor} = useAuth()
  // const isAdmin = auth?.data?.userType === 'ADMIN'
  // const isPartner = auth?.data?.userType === 'CLIENT'
  // const isFranchise = auth?.data?.userType === 'FRANCHISE'
  // const isAuditor = auth?.data?.userType === 'AUDITOR'

  const sidebarPayoad = [
    {
      title: 'Client',
      route: '/apps/client',
      icon: 'grid-frame',
      fontIcon: '',
      hasBullet: false,
    },
    {
      title: 'Billing',
      route: '/apps/billing',
      icon: 'grid-frame',
      fontIcon: '',
      hasBullet: false,
    },
    {
      title: 'Monthly Volume',
      route: '/apps/monthly-volume',
      icon: 'grid-frame',
      fontIcon: '',
      hasBullet: false,
    },
    {
      title: 'Franchisee',
      route: '/apps/franchisee',
      icon: 'grid-frame',
    },
    {
      title: 'App User',
      route: '/apps/user',
      icon: 'switch',
    },
    {
      title: 'Supply Chain',
      route: '/apps/facilities',
      icon: 'maximize',
      subMenu: [
        {
          title: 'Facility Information',
          route: '/apps/facilities/user-information',
        },
        {title: 'Compliance', route: '/apps/facilities/compilance'},
        {
          title: 'Processing Capacity',
          route: '/apps/facilities/processing-capacity',
        },
      ],
    },
    {
      title: 'Waste Collector',
      route: '/apps/waste-source',
      icon: 'switch',
    },
    {
      title: 'Transactions',
      route: '/apps/transactions',
      icon: 'maximize',
    },

    {
      title: 'Settings',
      route: '/apps/settings',
      icon: 'maximize',
    },
    {
      title: 'Plastic Credits',
      route: '/apps/plastic-credits',
      icon: 'maximize',
      subMenu: [
        {
          title: 'Unaudited',
          route: '/apps/plastic-credits/unaudited',
        },
        {title: 'Audited', route: '/apps/plastic-credits/audited'},
        {title: 'Retired', route: '/apps/plastic-credits/retired'},
      ],
    },
    {
      title: 'Product Passport',
      route: '/apps/product-passport',
      icon: 'maximize',
    },
  ]

  const filterSidebar = (arrayList) => {
    return sidebarPayoad.filter((obj) => !arrayList.includes(obj.title))
  }

  const payloadSidebar = isSponsor
    ? []
    : isAdmin
    ? [sidebarPayoad[0], sidebarPayoad[1], sidebarPayoad[2]]
    : isPartner
    ? filterSidebar(['Client', 'Product Passport', 'Billing', 'Monthly Volume'])
    : isFranchise
    ? filterSidebar([
        /* 'Settings', */ 'Client',
        'Franchisee',
        'Plastic Credits',
        'Billing',
        'Monthly Volume',
      ])
    : isAuditor
    ? [sidebarPayoad[9]]
    : [sidebarPayoad[8]]

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {!isAuditor && !isSponsor && (
        <>
          <SidebarMenuItem
            to='/apps/leaderboard'
            icon='parcel'
            title='Analytics'
            fontIcon='bi-layers'
          />
          {/* {isFranchise && (
            <SidebarMenuItem to='/apps/stock' icon='parcel' title='Stock' fontIcon='bi-layers' />
          )} */}
          {/*  <SidebarMenuItem
            to='/apps/monthly-reports'
            icon='parcel'
            title='Monthly Reports'
            fontIcon='bi-layers'
          />{' '} */}
          <SidebarMenuItem to='/apps/reports' icon='parcel' title='Reports' fontIcon='bi-layers' />
        </>
      )}
      {!isSponsor && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
          </div>
        </div>
      )}
      {payloadSidebar.map((sidebarName: any, index) => {
        return sidebarName?.subMenu?.length ? (
          <SidebarMenuItemWithSub
            key={index + 1 + ''}
            to={sidebarName.route}
            title={sidebarName.title}
            fontIcon='bi-layers'
            icon={sidebarName.icon}
          >
            {sidebarName?.subMenu.map((eachSubMenu, eachSubInd) => {
              return (
                <SidebarMenuItem
                  key={eachSubInd + 1 + ''}
                  to={eachSubMenu.route}
                  title={eachSubMenu.title}
                  hasBullet={true}
                />
              )
            })}
          </SidebarMenuItemWithSub>
        ) : (
          <SidebarMenuItem
            to={sidebarName.route}
            icon={sidebarName.icon}
            fontIcon='bi-layers'
            key={index + 1 + ''}
            title={sidebarName.title}
            hasBullet={sidebarName.hasBullet || false}
          />
        )
      })}
    </>
  )
}

export {SidebarMenuMain}
