/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  items?: number
  data?: any
}

const ListsWidget4: React.FC<Props> = ({className, items = 6, data}) => {
  var newData =
    data &&
    Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }))
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Top 5 Customer</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Latest tech trends</span> */}
        </h3>
        {/*  <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {newData.map((eachData, eachInd) => (
          <div key={eachInd + 1 + ''} className='d-flex align-items-sm-center mb-7'>
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <img
                  src={toAbsoluteUrl('/media/svg/brand-logos/cust.png')}
                  className='h-50 align-self-center'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  {eachData.name}
                </a>
                {/* <span className='text-muted fw-semibold d-block fs-7'>Mark, Rowling, Esther</span> */}
              </div>
              <span className='badge badge-light fw-bold my-2'>{eachData.value}</span>
            </div>
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
