import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

export const header = [
  {
    label: 'S.N',
    value: 'S.N',
  },
  {
    label: 'Date',
    value: 'collectionDate',
  },
  {
    label: 'Type',
    value: 'wasteSource',
  },
  {
    label: 'Batch Id',
    value: 'id',
  },
  {
    label: 'Facility',
    value: 'centreInfo[name]',
  },
  {
    label: 'Franchisee',
    value: 'franchiseName',
  },
  {
    label: 'Category',
    value: 'categoryName',
  },
  {
    label: 'Item',
    value: 'remark',
  },
  {
    label: 'Quantity',
    value: 'quantityDeposit_remark',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Supplier',
    value: 'B2B',
  },
  {
    label: 'Point of Origin',
    value: 'mapLocation',
  },
  // {
  //   label: 'Contact',
  //   value: 'customerInfo[mobile]',
  // },
  {
    action: ['view', 'id', 'qr'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
