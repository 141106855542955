import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {UsersTable} from '../table/UsersTable'
import {UserEditModal} from '../form-edit-modal/CoomonEditModal'
import {KTCard, initialQueryState} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getUserById} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {useFetchCommon} from '../../../../../../_metronic/helpers/crud-helper/useQuery'

const UsersList = () => {
  const {auth} = useAuth()
  const hideDropdown = auth?.data?.userType === 'SMART_CENTRE' || false
  const {data} = useQuery(
    `users?page=0&size=10&type=FRANCHISE`,
    () => getUserById('', 'users?page=0&size=10&type=FRANCHISE'),
    {
      enabled: hideDropdown,
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )
  const {updateState} = useQueryRequest()
  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  const {responseData: facilityTypeList} = useFetchCommon({
    api: 'users?page=0&size=10&type=PICKUP_POINT',
    label: ['personalDetails', 'name'],
    isDropDown: true,
  })
  useEffect(() => {
    if (data && data.length) {
      let dropdownData: any = [{label: 'Select One', value: ''}]
      data.map((eachData) => {
        return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
      })
      setCategoriesList(dropdownData)
      updateState({
        enabled: true,
        initialApi: `categories/${dropdownData[0]?.value}/items`,
        ...initialQueryState,
      })
    }
  }, [data])

  const generateYearOptions = (startYear: any) => {
    const currentYear = new Date().getFullYear()
    const years: any = []

    for (let year = currentYear; year >= startYear; year--) {
      years.push({value: year.toString(), label: `${year}`})
    }
    return years
  }

  const options = generateYearOptions(2015)
  const facilityType = [
    {value: '', label: 'Select one'},
    {value: 'COLLECTION', label: 'COLLECTION'},
    {value: 'PRODUCTION', label: 'PRODUCTION'},
    {value: 'DISPATCH', label: 'DISPATCH'},
  ]
  /* COLLECT PROCESS DISPATCH */

  const searchElements = [
    {
      type: 'select',
      value: 'select',
      options: options,
      name: 'start_date',
      label: 'Year',
    },
    {
      type: 'select',
      value: 'select',
      options: facilityType,
      name: 'status',
      label: 'Type',
    },
    {
      type: 'select',
      value: 'select',
      // options: facilityTypeList,
      options: [{value: '', label: 'Select one'}, ...facilityTypeList],
      name: 'franchiseeid',
      label: 'Facility',
    },
  ]
  return (
    <KTCard>
      <UsersListHeader
        showDownload
        showResetButton={true}
        initialApi='collect/orders'
        searchElements={searchElements}
        placeholder='Search Category'
      />
      <UsersTable initialApi='collect/orders' showPagination={true} columnProps={roleColumns} />
      <UserEditModal showViewPage getByIdApi='collect/orders/' formName='CollectOrdersModalForm' />
    </KTCard>
  )
}

const InvenotryListsWrapper = () => (
  <QueryRequestProvider initialValue={{enabled: false}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InvenotryListsWrapper}
