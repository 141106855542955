import {KTCard} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {UsersListHeader} from '../../../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {ListViewProvider} from '../../../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UserEditModal} from '../../../data-administration/data-admininstration-list/form-edit-modal/CoomonEditModal'
import {UsersTable} from '../../../data-administration/data-admininstration-list/table/UsersTable'
import {roleColumns} from './_columns'

const mockedData = [
  {
    gender: 'Male',
    email: 'ashok@gmail.com',
    fullAddress: 'New Baneshwor, Kathmandu',
    mobile: '9860105561',
    joinDate: '2023/09/05',
    currentLevel: 'Manager',
    pointEarned: 45,
    pointRedeemed: 5,
    name: 'Ashok Karki',
    birthday: '1992/12/22',
  },
  {
    gender: 'Femal',
    email: 'aleshika@gmail.com',
    fullAddress: 'Old Baneshwor, Kathmandu',
    mobile: '9860105560',
    joinDate: '2023/09/06',
    currentLevel: 'Employee',
    pointEarned: 5,
    pointRedeemed: 15,
    name: 'Aleshki Chheri',
    birthday: '1996/06/06',
  },
]

const UserManagementList = () => {
  return (
    <KTCard>
      <UsersListHeader label='Add New' />
      <UsersTable /* mockedData={mockedData} */ columnProps={roleColumns} />
      <UserEditModal formName='CertificationModalForm' />
    </KTCard>
  )
}

const CertificationsManagementWrapper = () => (
  <QueryRequestProvider initialValue={{initialApi: 'certifications', status: 'ACTIVE'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UserManagementList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CertificationsManagementWrapper}
